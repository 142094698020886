/* eslint-disable @typescript-eslint/no-use-before-define */
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import Logo from 'components/icons/Logo';
import LogoFull from 'components/icons/LogoFull';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import styles from './navBar.module.css';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const supabaseClient = useSupabaseClient();

  const router = useRouter();

  const urlPrefix = '/';

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <a href="#skip" className="sr-only focus:not-sr-only">
        Skip to content
      </a>
      <header className={styles.wrapper}>
        <nav className={styles.inner} aria-label="Global">
          <div className={styles.logoWrapper}>
            <span className="sr-only">Descripti.ai</span>
            <div className={styles.fullLogo}>
              <Link href="/">
                <LogoFull />
              </Link>
            </div>
            <div className={styles.dotsLogo}>
              <Link href="/">
                <Logo />
              </Link>
            </div>
          </div>

          <div
            className={`${styles.nav} ${
              isOpen ? styles.activeMenu : styles.inactiveMenu
            }`}
          >
            <div className="flex flex-col">
              <div className={styles.navLinks}>
                <Link href={`${urlPrefix}#features`} onClick={handleMenuToggle}>
                  Features
                </Link>
                <Link href="/pricing" onClick={handleMenuToggle}>
                  Pricing
                </Link>
                <Link href={`${urlPrefix}#faq`} onClick={handleMenuToggle}>
                  FAQs
                </Link>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <Link href="/account">Account</Link>
            <span
              className={styles.buttonFull}
              onClick={async () => {
                await supabaseClient.auth.signOut();
                router.push('/signin');
              }}
            >
              Sign out
            </span>
          </div>
        </nav>
      </header>
    </>
  );
}
