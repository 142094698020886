import useHover from 'hooks/useHover';
import { useEffect } from 'react';

import styles from './tooltip.module.css';

interface TooltipProps {
  text: string;
  direction: 'left' | 'right' | 'top' | 'bottom';
  children: React.ReactNode;
}

export default function Tooltip({ text, direction, children }: TooltipProps) {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  let tooltipState = isHovered ? 'visible' : 'hidden';

  useEffect(() => {
    if (isHovered) {
      setTimeout(() => {
        tooltipState = 'hidden';
      }, 3000);
    }
  }, [isHovered]);

  return (
    <div className={styles.wrapper} ref={hoverRef}>
      {children}
      <div
        className={`${styles.container} ${styles[direction]} ${styles[tooltipState]}`}
      >
        <span className={styles.tooltip}>{text}</span>
      </div>
    </div>
  );
}
