const Logo = ({ className = '', ...props }) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <circle
      cx="22.124"
      cy="8.17619"
      r="5.78144"
      transform="rotate(45 22.124 8.17619)"
      fill="#83D3FF"
    />
    <circle
      cx="36.0718"
      cy="22.1244"
      r="5.78144"
      transform="rotate(45 36.0718 22.1244)"
      fill="#FC73FF"
    />
    <circle
      cx="22.124"
      cy="36.0697"
      r="5.78144"
      transform="rotate(45 22.124 36.0697)"
      fill="#D5FEAB"
    />
    <circle
      cx="8.17578"
      cy="22.1244"
      r="5.78144"
      transform="rotate(45 8.17578 22.1244)"
      fill="#FFE500"
    />
  </svg>
);

export default Logo;
