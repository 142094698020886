/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Share, Toc } from 'components/blog';
import { useEffect, useState } from 'react';

import styles from './sidebar.module.css';

export default function Sidebar({ post }: any) {
  const [isUnstuck, setIsUnstuck] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const heightToHideFrom = 435;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      isUnstuck && setIsUnstuck(false);
    } else {
      setIsUnstuck(true);
    }
  };

  const { slug, title, toc } = post;

  return (
    <aside
      className={`${styles.wrapper} ${
        isUnstuck ? styles.unstick : styles.stick
      }`}
    >
      {toc && <Toc toc={toc} unstuck={isUnstuck} />}
      {slug && title && <Share slug={slug} title={title} unstuck={isUnstuck} />}
    </aside>
  );
}
