interface GetPathProps {
  props?: 'prevPath' | 'currentPath';
}

/* eslint-disable consistent-return */
export function storePathValues() {
  const storage = globalThis?.sessionStorage;
  if (!storage) return;

  const prevPath = storage.getItem('currentPath');

  storage.setItem('prevPath', prevPath || '');

  const currentPath = globalThis.location.pathname;
  if (currentPath !== '/help') {
    // Only store the current path if it's not the help page
    storage.setItem('currentPath', globalThis.location.pathname);
  }
}

export function getPathValues(props?: GetPathProps['props']) {
  const storage = globalThis?.sessionStorage;
  if (!storage) return;

  const prevPath = storage.getItem('prevPath');
  const currentPath = storage.getItem('currentPath');

  if (props === 'prevPath') return prevPath;
  if (props === 'currentPath') return currentPath;

  return { prevPath, currentPath };
}
