import Icon from 'components/ui/icons';
import Image from 'next/image';
import Link from 'next/link';

import styles from './branding.module.css';

interface BrandingProps {
  sidebarState?: 'open' | 'closed';
  toggleSidebar: () => void;
}

export default function Branding(props: BrandingProps) {
  return (
    <div className={styles.branding} data-branding>
      <div className={styles.brandingLogos}>
        <Link href="/">
          <Image
            className={styles.brandingLogo}
            src="/brand/logo.svg"
            alt="Descripti.ai"
            width={70}
            height={70}
            priority
          />
        </Link>
        {props.sidebarState === 'open' && (
          <Link href="/">
            <Image
              className={styles.brandingLogoFull}
              data-full-logo
              src="/brand/logo-full.svg"
              alt="Descripti.ai"
              width={241}
              height={44}
              priority
            />
          </Link>
        )}
      </div>
      <button
        onClick={props.toggleSidebar}
        className={styles.brandingSidebarTrigger}
      >
        {props.sidebarState === 'closed' ? (
          <Icon
            name="openSidebar"
            framed={true}
            ttText="Show sidebar"
            ttDir="right"
            kind="sidebar-primary"
          />
        ) : (
          <Icon name="closeSidebar" framed={true} kind="sidebar-primary" />
        )}
      </button>
    </div>
  );
}
