import type { Price } from 'types';

// Gets the URL of the site.
export const getURL = () => {
  let url =
    process?.env?.NEXT_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
    process?.env?.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
    'http://localhost:3000/';
  // Make sure to include `https://` when not localhost.
  url = url.includes('http') ? url : `https://${url}`;
  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === '/' ? url : `${url}/`;
  return url;
};

// Fetches data from the API.
export const postData = async ({
  url,
  data,
}: {
  url: string;
  data?: { price: Price };
}) => {
  console.log('posting,', url, data);

  const res: Response = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    console.log('Error in postData', { url, data, res });

    throw Error(res.statusText);
  }

  return res.json();
};

// Converts the seconds into a readable format.
export const toDateTime = (secs: number) => {
  const t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

// Converts the date string into a readable format.
export const convertDateToString = (date: string) => {
  const d = new Date(date);
  return d.toDateString();
};

// Converts the generated metadata into a usable object.
// Usage: const meta = convertMetaString(metadata);
export const convertMetaString = (meta: string) => {
  if (meta) {
    let metaStrip = meta.replace(/(\r\n|\n|\r)/gm, '');
    metaStrip = metaStrip.replace(/Title:/gm, '||');
    metaStrip = metaStrip.replace(/Description:/gm, '||');
    metaStrip = metaStrip.replace(/Meta Keywords:/gm, '||');

    const metaArr = metaStrip.split('||');
    const metaFiltered = metaArr.filter((item) => item !== '');

    metaFiltered.forEach((item, index) => {
      metaFiltered[index] = item.trim();
    });

    const metaObj = {
      title: metaFiltered[0],
      description: metaFiltered[1],
      keywords: metaFiltered[2],
    } as { title: string; description: string; keywords: string };

    return metaObj;
  }
  return null;
};

// Converts GPT response into a usable object/array.
// Usage: const gpt = convertGPTString(gptResponse);
export const convertGPTString = (gpt: string) => {
  const cleanResponse = (data: any) => {
    let cleanedIntro = '' as string;
    const cleanedData = [] as Array<string>;

    let splitData = data.split('\n');
    splitData = splitData.filter((item: string) => item !== '');
    splitData = splitData.filter((item: string) => item !== '---');

    let count = 0;

    splitData.forEach((item: string) => {
      const firstChar = item.charAt(0);

      if (count === 0) {
        // Intro
        cleanedIntro = item;
      } else if (firstChar.match(/[0-9]/)) {
        const cleanNumber = item.replace(/^[0-9]. /, '');
        cleanedData.push(cleanNumber);
      } else {
        cleanedData.push(item);
      }

      // eslint-disable-next-line no-plusplus
      count++;
    });

    return { cleanedData, cleanedIntro };
  };

  if (gpt) {
    const data = cleanResponse(gpt);
    return { intro: data.cleanedIntro, response: data.cleanedData };
  }

  return null;
};

export const reportGeneration = async (e: any, g: any) => {
  e.preventDefault();

  try {
    const response = await fetch('/api/report-generation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(g),
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return null;
};
