/* eslint-disable import/no-cycle */
import { Author, CoverImage } from 'components/blog';
import Link from 'next/link';
import type { ArticlePreviewProps } from 'types';

import styles from './articleCard.module.css';

export default function ArticleCard({
  content,
  showAuthor,
}: ArticlePreviewProps) {
  const { title, coverImage, coverBg, author, slug, excerpt } = content || {};

  return (
    <div className={styles.wrapper}>
      <CoverImage slug={slug} title={title} src={coverImage} bg={coverBg} />

      <div className={styles.textContainer}>
        <div>
          <Link href={`/blog/${slug}`} className="no-underline hover:underline">
            <h3 className="mb-3 text-[1.33rem] leading-snug tracking-tight">
              {title}
            </h3>
          </Link>
          <p className={styles.excerpt}>{excerpt}</p>
        </div>
        {showAuthor && <Author content={author} />}
      </div>
    </div>
  );
}
