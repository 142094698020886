import EnData from './en';

interface LocaleProps {
  lang: string | any;
}

export default function Locale({ lang }: LocaleProps) {
  switch (lang) {
    default:
      return EnData();
  }
}
