/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */

import styles from './icons.module.css';
import Tooltip from './tooltip';

interface IconProps {
  name: string;
  ttText?: string;
  ttDir?: 'left' | 'right' | 'top' | 'bottom';
  framed?: boolean;
  kind?:
    | 'dashboard-primary'
    | 'dashboard-secondary'
    | 'sidebar-primary'
    | 'sidebar-primary-no-borders'
    | 'sidebar-secondary';
  size?: number;
}

export default function Icon({ name, ttDir, ttText, framed, kind }: IconProps) {
  const size = 24;

  // Get the icon by name
  function getIcon(iconName: { name: string }) {
    switch (iconName.name) {
      case 'openSidebar':
        return <OpenSidebarIcon />;
      case 'closeSidebar':
        return <CloseSidebarIcon />;
      case 'dashboard':
        return <DashboardIcon />;
      case 'persona':
        return <PersonaIcon />;
      case 'add':
        return <AddIcon />;
      case 'delete':
        return <DeleteIcon />;
      case 'expand':
        return <ExpandIcon />;
      case 'collapse':
        return <CollapseIcon />;
      case 'close':
        return <CloseIcon />;
      case 'tools':
        return <ToolsIcon />;
      case 'products':
        return <ProductsIcon />;
      case 'tick':
        return <TickIcon />;
      case 'danger':
        return <DangerIcon />;
      case 'bot':
        return <BotIcon />;
      case 'bolt':
        return <BoltIcon />;
      case 'favorites':
        return <FavoritesIcon />;
      case 'copy':
        return <CopyIcon />;
      case 'chevron':
        return <ChevronIcon />;
      case 'copied':
        return <CopiedIcon />;
      case 'trash':
        return <TrashIcon />;
      case 'taskComplete':
        return <TaskCompleteIcon />;
      case 'taskIncomplete':
        return <TaskIncompleteIcon />;
      case 'star':
        return <StarIcon />;
      case 'settings':
        return <SettingsIcon />;
      default:
        return null;
    }
  }

  // Wrap the icon in a <svg> tag
  function iconWrapper({ name, size }: IconProps) {
    return (
      <svg
        width={size}
        height={size}
        fill="none"
        viewBox={`0 0 ${size} ${size}`}
      >
        {getIcon({ name })}
      </svg>
    );
  }

  // Wrap the icon in a <div> tag if framed
  function framedIcon({ name, size, framed, kind }: IconProps) {
    if (framed) {
      return (
        <div className={`${styles.framed} ${kind && styles[kind]}`}>
          {iconWrapper({ name, size, kind })}
        </div>
      );
    }

    return iconWrapper({ name, size, kind });
  }

  // Wrap the icon in a <Tooltip> tag if ttText and ttDir are provided
  if (ttText && ttDir) {
    return (
      <Tooltip text={ttText} direction={ttDir}>
        {framedIcon({ name, size, framed, kind })}
      </Tooltip>
    );
  }

  // Return the icon
  return framedIcon({ name, size, framed, kind });
}

export const OpenSidebarIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M6.833 20 5.5 18.5l5.333-6-5.333-6L6.833 5l6.667 7.5L6.833 20Z"
      />
      <path fill="currentColor" d="M18 19.5v-15h-2v15h2Z" />
    </>
  );
};

export const CloseSidebarIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M17.167 4 18.5 5.5l-5.333 6 5.333 6-1.333 1.5-6.667-7.5L17.167 4Z"
      />
      <path fill="currentColor" d="M6 4.5v15h2v-15H6Z" />
    </>
  );
};

export const DashboardIcon = () => {
  return <circle cx="12" cy="12" r="5" fill="currentColor" />;
};

export const PersonaIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M10.2248 14.4316c.4418 0 .8-.3582.8-.8 0-.4419-.3582-.8-.8-.8-.44183 0-.8.3581-.8.8 0 .4418.35817.8.8.8Zm5.6002-.8c0 .4418-.3582.8-.8.8s-.8-.3582-.8-.8c0-.4419.3582-.8.8-.8s.8.3581.8.8Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.625 20.8316c4.4182 0 8-3.5818 8-8 0-4.41831-3.5818-8.00003-8-8.00003-4.41828 0-8 3.58172-8 8.00003 0 4.4182 3.58172 8 8 8Zm0-1.6c3.5346 0 6.4-2.8654 6.4-6.4 0-.6691-.1026-1.3141-.293-1.9202-.6677.1806-1.37.277-2.0948.277-2.9785 0-5.5768-1.62771-6.95398-4.04215C7.62875 8.21146 6.225 10.3576 6.225 12.8316c0 3.5346 2.86538 6.4 6.4 6.4Z"
        clipRule="evenodd"
      />
    </>
  );
};

export const ToolsIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.791 5.67954c-.2743-.27431-.7191-.27431-.9934 0l-.6128.61275c-.7832-.37418-1.7498-.23688-2.3985.41189L7.33613 14.1543l3.97337 3.9734 7.4501-7.4501c.6488-.6488.7861-1.61535.4119-2.39862l.6128-.61274c.2743-.2743.2743-.71905 0-.99335l-.9933-.99335Zm-2.9979 5.97786-4.4836 4.4836-1.98667-1.9867 4.48357-4.48355 1.9867 1.98665Zm1.2772-1.2771.696-.69607c.2743-.27431.2743-.71905 0-.99336l-.9934-.99335c-.2742-.2743-.719-.2743-.9933 0l-.6961.69607 1.9868 1.98671Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m5.26001 20.1893 1.4903-5.4636 3.97309 3.9736-5.46339 1.49Z"
      />
    </>
  );
};

export const ProductsIcon = () => {
  return (
    <>
      <path
        fill="#000"
        d="M6.625 17.8316h2v-2h-2v2Zm4 0h8v-2h-8v2Zm-4-4h2v-2h-2v2Zm4 0h8v-2h-8v2Zm-4-4.00003h2v-2h-2v2Zm4 0h8v-2h-8v2Z"
      />
    </>
  );
};

export const DeleteIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M6.2253 4.81108c-.39053-.39052-1.02369-.39052-1.41422 0-.39052.39053-.39052 1.02369 0 1.41422L10.5858 12l-5.77466 5.7747c-.39052.3905-.39052 1.0237 0 1.4142.39053.3905 1.02369.3905 1.41421 0L12 13.4142l5.7747 5.7747c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L13.4142 12l5.7748-5.7747c.3905-.39053.3905-1.02369 0-1.41422-.3905-.39052-1.0237-.39052-1.4142 0L12 10.5858 6.2253 4.81108Z"
      />
    </>
  );
};

export const AddIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M2 12C2 6.47715 6.47715 2 12 2c5.5228 0 10 4.47715 10 10 0 5.5228-4.4772 10-10 10-5.52285 0-10-4.4772-10-10Zm10-8c-4.41828 0-8 3.58172-8 8 0 4.4183 3.58172 8 8 8 4.4183 0 8-3.5817 8-8 0-4.41828-3.5817-8-8-8Z"
      />
      <path
        fill="currentColor"
        d="M13 7c0-.55228-.4477-1-1-1s-1 .44772-1 1v4H7c-.55228 0-1 .4477-1 1s.44772 1 1 1h4v4c0 .5523.4477 1 1 1s1-.4477 1-1v-4h4c.5523 0 1-.4477 1-1s-.4477-1-1-1h-4V7Z"
      />
    </>
  );
};

export const ExpandIcon = () => {
  return (
    <>
      <path
        style={{ transform: 'translate(-0.5px, -0.5px)' }}
        fill="currentColor"
        d="M13.7908 14.0799v7.3666h-2.2667v-7.3666H4.15747v-2.2667h7.36663V4.44653h2.2667v7.36667h7.3667v2.2667h-7.3667Z"
      />
    </>
  );
};

export const CollapseIcon = () => {
  return <path fill="currentColor" d="M5.125 13.832h15v-2h-15v2Z" />;
};

export const CloseIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M8.029 18.843 18.635 8.236l-1.414-1.414L6.615 17.43l1.414 1.414Z"
      />
      <path
        fill="currentColor"
        d="M6.615 8.236 17.22 18.843l1.414-1.414L8.03 6.822 6.615 8.236Z"
      />
    </>
  );
};

export const TickIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M10.2426 16.3137 6 12.071l1.41421-1.4142 2.82839 2.8285 5.6569-5.6569 1.4142 1.41422-7.0711 7.07108Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 5c0-2.20914 1.79086-4 4-4h14c2.2091 0 4 1.79086 4 4v14c0 2.2091-1.7909 4-4 4H5c-2.20914 0-4-1.7909-4-4V5Zm4-2h14c1.1046 0 2 .89543 2 2v14c0 1.1046-.8954 2-2 2H5c-1.10457 0-2-.8954-2-2V5c0-1.10457.89543-2 2-2Z"
        clipRule="evenodd"
      />
    </>
  );
};

export const TaskCompleteIcon = () => {
  return (
    <>
      <rect
        width="2"
        height="16.0644"
        x="18.79"
        y="4.43555"
        fill="currentColor"
        rx="1"
        transform="rotate(40.5049 18.79 4.43555)"
      />
      <rect
        width="2"
        height="9"
        x="3.0293"
        y="12.1084"
        fill="currentColor"
        rx="1"
        transform="rotate(-49.4951 3.0293 12.1084)"
      />
    </>
  );
};

export const TaskIncompleteIcon = () => {
  return (
    <path
      fill="currentColor"
      d="M6.2253 4.81108c-.39053-.39052-1.02369-.39052-1.41422 0-.39052.39053-.39052 1.02369 0 1.41422L10.5858 12l-5.77466 5.7747c-.39052.3905-.39052 1.0237 0 1.4142.39053.3905 1.02369.3905 1.41421 0L12 13.4142l5.7747 5.7747c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L13.4142 12l5.7748-5.7747c.3905-.39053.3905-1.02369 0-1.41422-.3905-.39052-1.0237-.39052-1.4142 0L12 10.5858 6.2253 4.81108Z"
    />
  );
};

export const DangerIcon = () => {
  return (
    <>
      <svg width={24} height={24} fill="none">
        <path
          d="M12 6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1ZM12 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export const BoltIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M12.0904 10.1043h6.1453v.2782L6.77217 20.9004h-.35679l5.38492-7.9248H5.76367v-.3355l9.51923-9.54049h.4717l-3.6642 7.00469Z"
      />
    </>
  );
};

export const BotIcon = () => {
  return (
    <>
      <path d="M14.125 13h-4v2h4v-2Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.125 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM18.125 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.749 14.666A6 6 0 0 0 8.125 18h8c2.44 0 4.54-1.456 5.478-3.547A2.997 2.997 0 0 0 22.875 12c0-1.013-.503-1.91-1.272-2.452A6.001 6.001 0 0 0 16.125 6h-8A6 6 0 0 0 2.75 9.334a3 3 0 0 0 0 5.332ZM8.125 8h8c1.384 0 2.603.702 3.322 1.77.276.69.428 1.442.428 2.23s-.152 1.54-.428 2.23A3.996 3.996 0 0 1 16.125 16h-8a4 4 0 0 1 0-8Z"
        fill="currentColor"
      />
    </>
  );
};

export const FavoritesIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.4583 19.4982h-1.5236l-3.7204-3.7205c-.3255-.3254-.8531-.3254-1.1786 0l-3.72034 3.7205h-1.5237V8.66491c0-1.38071 1.1193-2.5 2.5-2.5h6.66664c1.3808 0 2.5 1.11928 2.5 2.5V19.4982ZM16.7917 8.66491c0-.46023-.3731-.83333-.8334-.83333H9.29166c-.46023 0-.83333.3731-.83333.83333v8.33329l2.39897-2.399c.9763-.9762 2.5592-.9762 3.5355 0l2.3989 2.399V8.66491Z"
        clipRule="evenodd"
      />
    </>
  );
};

export const CopyIcon = () => {
  return (
    <>
      <rect
        width="10.0385"
        height="11.5"
        x="9.21143"
        y="7.75"
        stroke="currentColor"
        strokeWidth="1.5"
        rx="2.25"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7 3h6.2913v1.5H7c-.82843 0-1.5.67157-1.5 1.5v8H4V6c0-1.65686 1.34315-3 3-3Z"
        clipRule="evenodd"
      />
    </>
  );
};

export const ChevronIcon = () => {
  return (
    <path
      fill="currentColor"
      d="m20.125 14.499-1.5 1.333-6-5.333-6 5.333-1.5-1.333 7.5-6.667 7.5 6.667Z"
    />
  );
};

export const CopiedIcon = () => {
  return (
    <svg width={24} height={24} fill="none">
      <path
        d="M13 7H7V5h6v2ZM13 11H7V9h6v2ZM7 15h6v-2H7v2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 19V1h14v4h4v18H7v-4H3Zm12-2V3H5v14h10Zm2-10v12H9v2h10V7h-2Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const TrashIcon = () => {
  return (
    <>
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M6.75 7.75h9.5V17c0 1.2426-1.0074 2.25-2.25 2.25H9c-1.24264 0-2.25-1.0074-2.25-2.25V7.75Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 3c-.55228 0-1 .44772-1 1H6v1.5h11V4h-2c0-.55228-.4477-1-1-1H9Z"
        clipRule="evenodd"
      />
    </>
  );
};

export const StarIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M12.0001 4.67749c.2904 0 .5559.16409.6858.42385l1.9773 3.95476 4.348.63243c.2888.04201.5287.2443.6189.52187.0901.2775.0149.5822-.194.7859l-3.1645 3.0854.7119 4.3502c.047.2875-.0729.5768-.3096.7467-.2367.1699-.5492.191-.8065.0544l-3.8673-2.052-3.86737 2.052c-.25734.1366-.56984.1155-.80651-.0544-.23666-.1699-.35661-.4592-.30957-.7467l.71186-4.3502-3.16449-3.0854c-.20894-.2037-.28413-.5084-.19395-.7859.09017-.27757.33008-.47986.61886-.52187l4.34795-.63243 1.97742-3.95476c.1298-.25976.3953-.42385.6858-.42385Z"
      />
    </>
  );
};

export const SettingsIcon = () => {
  return (
    <>
      <path
        fill="currentColor"
        d="M12.4493 5.35417c-.4602 0-.8333.37309-.8333.83333 0 1.41021-1.70504 2.11645-2.70221 1.11928-.32544-.32544-.85308-.32544-1.17852 0-.32543.32544-.32543.85307 0 1.17851.99721.99721.29089 2.70221-1.1193 2.70221-.46024 0-.83334.3731-.83334.8333 0 .4603.3731.8334.83334.8334 1.4103 0 2.11636 1.7051 1.11925 2.7022-.32544.3254-.32544.853 0 1.1785.32544.3254.85308.3254 1.17851 0 .99716-.9972 2.70227-.291 2.70227 1.1193 0 .4602.3731.8333.8333.8333.4602 0 .8333-.3731.8333-.8333 0-1.4103 1.7051-2.1165 2.7023-1.1193.3254.3254.853.3254 1.1785 0 .3254-.3255.3254-.8531 0-1.1785-.9972-.9972-.291-2.7022 1.1192-2.7022.4603 0 .8334-.3731.8334-.8334 0-.4602-.3731-.8333-.8334-.8333-1.4101 0-2.1165-1.70496-1.1193-2.7022.3254-.32543.3254-.85307 0-1.17851-.3254-.32543-.8531-.32543-1.1785 0-.9972.9972-2.7022.29087-2.7022-1.11929 0-.46024-.3731-.83333-.8333-.83333Zm-2.4932.64752c.0951-1.29394 1.175-2.31419 2.4932-2.31419 1.3182 0 2.3981 1.02025 2.4932 2.31419.9822-.84772 2.4672-.80552 3.3993.12659.9321.93212.9743 2.41718.1266 3.39935 1.294.09504 2.3142 1.17497 2.3142 2.49317s-1.0202 2.3982-2.3142 2.4932c.8478.9822.8056 2.4673-.1265 3.3994-.9322.9321-2.4172.9743-3.3994.1265-.095 1.294-1.175 2.3143-2.4932 2.3143-1.3182 0-2.3982-1.0203-2.4932-2.3143-.98218.8478-2.46726.8056-3.39939-.1265-.93213-.9321-.97431-2.4172-.12656-3.3994-1.29393-.095-2.31418-1.175-2.31418-2.4932 0-1.3182 1.02027-2.39813 2.31424-2.49317-.84776-.98217-.80557-2.46724.12655-3.39936.93212-.93212 2.41717-.97431 3.39934-.12658Z"
      />
      <path
        fill="currentColor"
        d="M12.4493 10.3542c-.442 0-.866.1756-1.1785.4881-.3126.3126-.4882.7365-.4882 1.1785 0 .4421.1756.866.4882 1.1785.3125.3126.7365.4882 1.1785.4882.442 0 .866-.1756 1.1785-.4882.3126-.3125.4882-.7364.4882-1.1785 0-.442-.1756-.8659-.4882-1.1785-.3125-.3125-.7365-.4881-1.1785-.4881Zm-2.357-.69039c.6251-.62512 1.4729-.97631 2.357-.97631.8841 0 1.7319.35119 2.357.97631.6251.62509.9763 1.47299.9763 2.35699 0 .8841-.3512 1.7319-.9763 2.3571-.6251.6251-1.4729.9763-2.357.9763-.8841 0-1.7319-.3512-2.357-.9763-.62514-.6252-.97633-1.473-.97633-2.3571 0-.884.35119-1.7319.97633-2.35699Z"
      />
    </>
  );
};
