/* eslint-disable no-plusplus */
export default function getReadTime(content: string): number {
  const WPS = 275 / 60;

  let imagesCount = 0;
  const regex = /\w/;

  const wordsCount = content.split(' ').filter((word: string) => {
    if (word.includes('<img')) {
      imagesCount++;
    }
    return regex.test(word);
  }).length;

  const imagesTime = imagesCount * 4;
  let totalImageTime = 0;
  let imageFactor = 12;

  while (imagesCount) {
    totalImageTime += imageFactor;
    if (imageFactor > 3) {
      imageFactor--;
    }
    imagesCount--;
  }

  const minutes = Math.ceil(
    ((wordsCount - imagesTime) / WPS + totalImageTime) / 60
  );

  return minutes;
}
