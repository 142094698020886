export const ai = {
  model: 'text-curie-001',
  // model: 'text-davinci-003',
};

export const tones = [
  'fun',
  'professional',
  'romantic',
  'happy',
  'inspirational',
  'motivational',
  'thoughtful',
  'courageous',
  'confident',
  'inspiring',
  'hopeful',
  'rebellious',
];

export const genders = ['neutral', 'masculine', 'feminine'];

export const extraversion = [
  'very extraverted',
  'somewhat extraverted',
  'neutral',
  'somewhat introverted',
  'very introverted',
];

export const time = [
  'very busy',
  'somewhat busy',
  'neutral',
  'somewhat time rich',
  'very time rich',
];

export const mindset = [
  'very analytical',
  'somewhat analytical',
  'neutral',
  'somewhat creative',
  'very creative',
];

export const activeness = [
  'very passive',
  'somewhat passive',
  'neutral',
  'somewhat active',
  'very active',
];

export const curiosity = [
  'very adventurous',
  'somewhat adventurous',
  'neutral',
  'somewhat sensible',
  'very sensible',
];
