/* eslint-disable react/no-unescaped-entities */
import Icon from 'components/ui/icons';
import { useState } from 'react';

import styles from './features.module.css';

export default function Features() {
  const [activeFeature, setActiveFeature] = useState(0);

  const featureContent = {
    introTitle: 'Get to market faster',
    mainTitle: 'Let our tools generate product content for you.',
    description:
      'Give our tools the opportunity to generate content for your entire product flow from product titles and descriptions through to metadata and SEO content.',
    features: [
      {
        icon: 'tools',
        title: 'Titles, Descriptions and more...',
        description:
          "Just give us a few keywords, and we'll help you generate user-friendly, SEO-optimized and high-converting product content.",
      },
      {
        icon: 'persona',
        title: 'Personas',
        description:
          'When our AI creates content it will first consider the various characteristics of your target audience and make an informed decision on the best content to deliver.',
      },
      {
        icon: 'bolt',
        title: 'Full product flow',
        description:
          'Using your user personas, our AI tools will handle all of your content, from product titles to metadata to SEO content and beyond.',
      },
    ],
  };

  const handleFeatureHover = (index: number) => () => {
    setActiveFeature(index);

    setTimeout(() => {
      setActiveFeature(0);
    }, 7500);
  };

  return (
    <div id="features" className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.split}>
          <div className="lg:max-w-lg lg:pr-8">
            <p className={styles.subtitle}>{featureContent.introTitle}</p>
            <h2 className={styles.title}>{featureContent.mainTitle}</h2>
            <p className="mt-6 text-base text-gray-600">
              {featureContent.description}
            </p>
            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
              {featureContent.features.map((feature, i) => {
                return (
                  <div
                    key={i}
                    className={styles.feature}
                    onMouseOver={handleFeatureHover(i + 1)}
                  >
                    <Icon name={feature.icon} framed kind="dashboard-primary" />
                    <div>
                      <h5>{feature.title}</h5>
                      <p>{feature.description}</p>
                    </div>
                  </div>
                );
              })}
            </dl>
          </div>

          <div className={styles.popOutContainer}>
            <img
              src="/screenshots/titles.png"
              alt="Descripti.ai - Generate Titles"
              className={`${styles.screenshotPopOut} ${
                activeFeature === 1 && styles.showPopOut
              }`}
            />

            <img
              src="/screenshots/personas.png"
              alt="Descripti.ai - Personas"
              className={`${styles.screenshotPopOut} ${
                activeFeature === 2 && styles.showPopOut
              }`}
            />

            <img
              src="/screenshots/flow.png"
              alt="Descripti.ai - Product Flow"
              className={`${styles.screenshotPopOut} ${
                activeFeature === 3 && styles.showPopOut
              }`}
            />

            <img
              src="/screenshots/dashboard.png"
              alt="Descripti.ai Dashboard"
              className={`${styles.screenshotBg} ${
                activeFeature !== 0 && styles.blurBg
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
