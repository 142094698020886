import Link from 'next/link';

import styles from './cardSmall.module.css';

interface CardSmallProps {
  cardTitle: string;
  cardLinkText: string;
  cardLinkDesc?: string;
  cardLink: string;
  productFlow?: boolean;
}

export default function CardSmall(props: CardSmallProps) {
  return (
    <div className={`${styles.cardSmall} ${styles.productFlowCard}`}>
      <div className={styles.productFlowCardInner}>
        <h6 className={styles.cardSmallHeading}>{props.cardTitle}</h6>
        {props.cardLinkDesc && (
          <p className={styles.cardSmallDesc}>{props.cardLinkDesc}</p>
        )}
        <Link href={props.cardLink}>
          {props.cardLinkText ? props.cardLinkText : 'Create Now'}
        </Link>
      </div>
    </div>
  );
}
