import Image from 'next/image';
import Link from 'next/link';
import type { CoverImageProps } from 'types';

import styles from './coverImage.module.css';

export default function CoverImage({ title, src, bg, slug }: CoverImageProps) {
  const image = (
    <Image
      src={src}
      alt={`Cover Image for ${title}`}
      className="w-full"
      width={1300}
      height={630}
    />
  );
  return (
    <div
      className={styles.wrapper}
      style={{ backgroundColor: bg || '#efefef' }}
    >
      {slug ? (
        <Link href={`/blog/${slug}`} aria-label={title}>
          {image}
        </Link>
      ) : (
        image
      )}
    </div>
  );
}
