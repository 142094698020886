/* eslint-disable react/no-unescaped-entities */
import Link from 'next/link';

import styles from './usp.module.css';

export default function USP({ visible }: any) {
  return (
    <div className={`${styles.wrapper} ${!visible && styles.hide}`}>
      <div className="flex flex-row items-center justify-center gap-2">
        <p className="text-sm leading-6 text-white">
          <strong className="mr-2 bg-gradient-to-br from-[#FFC700] to-[#FFE600] bg-clip-text font-semibold text-transparent">
            Get early access!
          </strong>
          Limited availability
        </p>
        <Link
          href="/signup"
          className="flex-none rounded-md bg-white py-1 px-3.5 text-sm font-semibold text-purple-primary no-underline shadow-sm brightness-100 hover:bg-black/30 hover:text-white/100 hover:brightness-100"
        >
          Register
        </Link>
      </div>
    </div>
  );
}
