import { LinkedIn, Twitter } from 'components/logos';

import styles from './share.module.css';

export default function Share({ slug, title, unstuck }: any) {
  function convertSlugToText(s: string) {
    const newSlug = s.replace(/ /g, '%20');
    return newSlug;
  }

  const twitterShareUrl = `https://twitter.com/share?url=https://descripti.ai/blog/${slug}&text=${convertSlugToText(
    title
  )}&via=descriptiai`;

  const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=https://descripti.ai/blog/${slug}&title=${convertSlugToText(
    title
  )}`;

  return (
    <div
      className={`${styles.wrapper} ${unstuck ? styles.unstick : styles.stick}`}
    >
      <h6>Share</h6>
      <div className={styles.networks}>
        <a href={twitterShareUrl} title="Share on Twitter">
          <Twitter />
        </a>

        <a href={linkedInShareUrl} title="Share on LinkedIn">
          <LinkedIn />
        </a>
      </div>
    </div>
  );
}
