// Poppins
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
// Inter
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'styles/main.css';
import 'styles/chrome-bug.css';

import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import Layout from 'components/Layout';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import type { Database } from 'types_db';
import { GTM_ID, pageview } from 'utils/gtm';
import { storePathValues } from 'utils/path-values';
import { FlowContext } from 'utils/useFlow';
import { MyUserContextProvider } from 'utils/useUser';

export default function MyApp({ Component, pageProps }: any) {
  const router = useRouter();

  const [flow, setFlow] = useState<any>({ stage: 'none' });
  const [gens, setGens] = useState<any>([]);

  const [supabaseClient] = useState(() =>
    createBrowserSupabaseClient<Database>()
  );

  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  useEffect(() => storePathValues, [router.asPath]);

  const value = { flow, setFlow, gens, setGens };

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  return (
    <SessionContextProvider supabaseClient={supabaseClient}>
      <MyUserContextProvider>
        <FlowContext.Provider value={value}>
          <Layout>
            <Script
              id="gtag-base"
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer', '${GTM_ID}');
                `,
              }}
            />
            <Component {...pageProps} />
          </Layout>
        </FlowContext.Provider>
      </MyUserContextProvider>
    </SessionContextProvider>
  );
}
