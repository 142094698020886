import Link from 'next/link';
import { useEffect, useState } from 'react';
import type { ProgressBarProps } from 'types';
import { subscribe, unsubscribe } from 'utils/events';
import { useUser } from 'utils/useUser';

import styles from './quota.module.css';

const ProgressBar = ({ used, total }: ProgressBarProps) => {
  const percentage = (used / total) * 100;

  // console.log('Percentage: ', percentage);

  return (
    <div className={styles.progressBar} data-used={used} data-total={total}>
      <span style={{ width: `${percentage}%` }}>{used}</span>
    </div>
  );
};

export default function Quota() {
  const { user, subscription, credits } = useUser();

  const [liveCredits, setLiveCredits] = useState(credits);

  useEffect(() => {
    subscribe('credits-updated', (data) => setLiveCredits(data.detail));
    return () => {
      unsubscribe('credits-updated', (data) => setLiveCredits(data.detail));
    };
  }, []);

  // console.log('LIVE CREDITS: ', liveCredits);

  const quotaDetails = {
    tier: subscription?.prices?.products?.name,
    credits: subscription?.prices?.credits || 0,
  };

  // console.log('Quota: ', quotaDetails);
  // console.log('Subscription: ', subscription);

  function cleanseDate(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    ).getTime();
  }

  const today = new Date();
  const from = cleanseDate(today);

  // console.log('Today: ', from);

  const monthEnd = subscription?.current_period_end as unknown as Date;
  const renewalDate = new Date(monthEnd) as Date;
  const to = cleanseDate(renewalDate) as number;

  // console.log('Renewal Date: ', to);

  const msDays = 1000 * 60 * 60 * 24;
  let renewsIn = Math.floor((from - to) / msDays) as any;
  renewsIn = `${Math.abs(renewsIn)} days` as string;

  // console.log('Renewal Date: ', renewsIn);

  if (user !== null && liveCredits) {
    return (
      <div className={styles.wrapper}>
        <h3>Tokens 🎟️</h3>

        {liveCredits < quotaDetails?.credits ? (
          <>
            <p>
              {liveCredits}/{quotaDetails.credits} remaining this month
            </p>

            <ProgressBar used={liveCredits} total={quotaDetails.credits} />
          </>
        ) : (
          <p>You have {liveCredits} tokens remaining</p>
        )}

        <div>
          {quotaDetails.tier === 'Professional' ? (
            'Contact us to upgrade'
          ) : (
            <p>
              <Link href="/pricing">Upgrade</Link> | Tokens renew in {renewsIn}
            </p>
          )}
        </div>
      </div>
    );
  }

  return <div className={styles.wrapper}></div>;
}
