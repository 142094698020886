import { createContext, useContext } from 'react';

interface FlowData {
  flow: any;
  setFlow: any;
  gens: any;
  setGens: any;
}

export const FlowContext = createContext<FlowData>({
  flow: {},
  setFlow: () => {},
  gens: [],
  setGens: () => {},
});
export const useFlow = () => useContext(FlowContext);
