export default function Twitter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="#1DA1F2"
        d="M7.54752 21.7508c9.05668 0 14.01028-7.5034 14.01028-14.01028 0-.21312 0-.42528-.0144-.63648C22.507 6.407 23.3389 5.54392 24 4.55524c-.8986.3984-1.852.65956-2.8282.77472 1.028-.61531 1.7974-1.58322 2.1648-2.72352-.9665.57361-2.024.97783-3.1267 1.1952-.7424-.78942-1.7243-1.31216-2.7937-1.48732-1.0694-.17515-2.1668.00703-3.1222.51837-.9555.51134-1.7158 1.32332-2.1633 2.3103-.4474.98697-.5572 2.09391-.3121 3.14953-1.95771-.0982-3.87284-.60697-5.62115-1.49328-1.7483-.8863-3.29069-2.13034-4.52705-3.65136-.62967 1.084-.822528 2.36723-.5393 3.58842.28323 1.2212 1.02124 2.28853 2.06378 2.9847C2.41123 9.69804 1.64465 9.48663.96 9.10468v.0624c.000311 1.13682.39385 2.23862 1.11387 3.11832.72002.8798 1.72219 1.4835 2.83653 1.7086-.72492.1977-1.48553.2266-2.22336.0844.31477.9784.92739 1.8339 1.7522 2.447.82481.6131 1.82059.9531 2.84812.9726-1.02092.8024-2.19005 1.3958-3.44049 1.746-1.25044.3502-2.55766.4504-3.84687.2949 2.25183 1.445 4.87192 2.2115 7.54752 2.208"
      />
    </svg>
  );
}
