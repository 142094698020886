import { useState } from 'react';
import SimpleBar from 'simplebar-react';

import styles from './sidebar.module.css';
import { AccountToggle, Branding, Footer, Menu, Quota } from './sidebar/index';

export default function Sidebar() {
  const [sidebarState, setSidebarState] = useState<'open' | 'closed'>('open');
  const sidebarHandler = () => {
    if (sidebarState === 'closed') {
      setSidebarState('open');
    } else {
      setSidebarState('closed');
    }
  };

  return (
    <aside
      className={`${styles.wrapper} ${
        sidebarState === 'closed' ? styles.closed : styles.open
      }`}
    >
      {sidebarState === 'closed' ? (
        <div className={`${styles.wrapperContent} ${styles.closed}`}>
          <Branding
            sidebarState={sidebarState}
            toggleSidebar={sidebarHandler}
          />
          <Menu sidebarState={sidebarState} />

          {/* <div className={styles.sidebarFooter}>
            <Disclaimer />
          </div> */}
        </div>
      ) : (
        <SimpleBar
          forceVisible="y"
          autoHide={false}
          style={{ maxHeight: '100%', width: '100%', display: 'flex' }}
        >
          <div className={styles.wrapperContent}>
            <Branding
              sidebarState={sidebarState}
              toggleSidebar={sidebarHandler}
            />
            <Menu sidebarState={sidebarState} />

            <div className={styles.sidebarFooter}>
              <Quota />
              <AccountToggle />
              <Footer />
              {/* <Disclaimer /> */}
            </div>
          </div>
        </SimpleBar>
      )}
    </aside>
  );
}
