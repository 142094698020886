export default function KarmoonLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 710 494">
      <path
        shapeRendering="optimizeQuality"
        fill="currentColor"
        d="M154 148H85L60 88h-3l2 60H0V0h59l-2 60h3L88 0h64l-37 67-43 6v3l48 6 34 66Z"
      ></path>
      <path
        shapeRendering="optimizeQuality"
        fill="currentColor"
        d="M318 148h-63l-2-16h-40l-3 16h-61L180 0h108l30 148ZM231 33l-10 56h23l-10-56h-3Zm249 85 2 15 4 12v3h-63l-3-11-1-17-1-9-5-5-8-1h-21v43h-59V0h86c14 0 25 2 36 5 10 3 18 9 24 16s9 16 9 26-2 18-7 24c-4 6-12 10-24 11v3c7 0 13 3 18 6 4 3 8 7 10 12 2 4 3 10 3 15Zm-96-69v17h21l11-2c2-1 3-3 3-6l-2-7-7-2h-26Zm111 57V84h181v22H495Zm-287 61v148h-49v-96h-3l-13 96H66l-14-96h-3v96H0V167h94l9 98h2l9-98h94Zm89 151c-19 0-34-3-45-8-11-6-20-14-25-26-6-11-8-26-8-44 0-16 3-30 9-41 6-12 15-20 26-26a105 105 0 0 1 84 0c12 5 20 13 26 25 5 11 8 26 8 44 0 16-3 30-10 41-6 11-15 20-27 26-11 6-24 9-38 9Zm0-51c4 0 7-2 9-5 3-4 4-10 4-20s-2-17-4-21c-3-4-7-6-12-6-9 0-13 8-13 25 0 10 1 17 4 21 2 4 6 6 12 6Zm159 51c-18 0-33-3-45-8-11-6-19-14-25-26-5-11-8-26-8-44 0-16 3-30 10-41 6-12 15-20 26-26s24-9 39-9c18 0 33 3 45 8 11 6 20 14 25 26 6 11 8 26 8 44 0 16-3 30-9 41-7 11-16 20-27 26s-24 9-39 9Zm0-51c4 0 7-2 10-5 2-4 3-10 3-20s-1-17-4-21c-2-4-6-6-12-6-8 0-13 8-13 25 0 10 2 17 4 21 3 4 7 6 12 6Zm204 0V167h50v148h-97l-17-100h-2v100h-51V167h97l17 100h3ZM411 384c0 18-16 32-36 32-19 0-35-14-35-32 0-19 16-33 35-33 20 0 36 14 36 33Zm113 76c-21 0-38-16-38-35 0-5-4-9-10-9-5 0-9 4-9 9 0 10 4 20 9 28-17 9-51 24-104 24s-86-14-104-24c5-8 9-18 9-28 0-5-4-9-9-9-6 0-10 4-10 9 0 19-17 35-38 35-5 0-9 3-9 8s4 9 9 9c14 0 26-5 35-11 18 11 57 28 117 28s99-17 117-28c9 6 21 11 35 11 5 0 9-4 9-9s-4-8-9-8Z"
      ></path>
    </svg>
  );
}
