/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-shadow */
import LoadingDots from 'components/ui/LoadingDots';
import error from 'next/error';
import { useRouter } from 'next/router';
import { useState } from 'react';
import type { BillingInterval, Price, PricingCardProps } from 'types';
import { postData } from 'utils/helpers';
import { getStripe } from 'utils/stripe-client';
import { useUser } from 'utils/useUser';

import styles from './pricingCard.module.css';

export default function PricingCard({ content }: PricingCardProps) {
  const [billingInterval] = useState<BillingInterval>('month');
  const [priceIdLoading, setPriceIdLoading] = useState<string>();
  const { user, isLoading, subscription } = useUser();

  const router = useRouter();

  // console.log(content);

  const mostPopular = content?.name === 'Freelancer';

  const features = Object.entries(content?.metadata)
    .filter(([key]) => key.startsWith('feature_'))
    .map(([, value]) => value);

  const price = content?.prices?.find(
    (price) => price.interval === billingInterval
  ) as Price;

  if (!price) return null;

  const priceString = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: price.currency,
    minimumFractionDigits: 0,
  }).format((price?.unit_amount || 0) / 100);

  const handleCheckout = async (price: Price) => {
    setPriceIdLoading(price.id);

    if (!user) {
      const pushUrl = `/signup?status=onboarding&tier=${content.name}`;
      router.push(pushUrl);
      return;
    }

    if (subscription) {
      router.push('/account');
      return;
    }

    try {
      const { sessionId } = await postData({
        url: '/api/create-checkout-session',
        data: { price },
      });

      const stripe = await getStripe();

      if (stripe) {
        stripe.redirectToCheckout({ sessionId });
      } else {
        alert((error as unknown as Error)?.message);
      }
    } catch (error) {
      alert((error as Error)?.message);
    } finally {
      setPriceIdLoading(undefined);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.inner} ${mostPopular && styles.popular}`}>
        <h5>
          {content?.name} {mostPopular && <span>Most Popular</span>}
        </h5>

        <h2 className={styles.pricing}>
          {priceString}
          <span>/{billingInterval}</span>
        </h2>

        <p className={styles.description}>{content?.description}</p>

        <div className={styles.features}>
          {features.map((feature, i) => (
            <p key={i} className="">
              {feature}
            </p>
          ))}
        </div>

        <button
          disabled={isLoading}
          onClick={() => handleCheckout(price)}
          data-tier={content?.name}
          data-status="onboard"
        >
          {priceIdLoading ? (
            <LoadingDots version="spin" colour={true} />
          ) : content?.name === subscription?.prices?.products?.name ? (
            'Manage'
          ) : (
            `Choose ${content?.name}`
          )}
        </button>
      </div>
    </div>
  );
}
