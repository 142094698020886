import PersonaAvatar from 'components/ui/avatar';
import Link from 'next/link';
import { useUser } from 'utils/useUser';

import styles from './cardLarge.module.css';

interface CardLargeProps {
  cardTitle: string;
  cardSubtitle: string;
  cardLinkText: string;
  cardLink: string;
  showAvatars?: boolean;
}

export default function CardLarge(props: CardLargeProps) {
  const { avatars } = useUser();

  // console.log('CardLarge', props, avatars);

  return (
    <div className={`${styles.cardLarge} cardLarge`}>
      <h6 className={styles.cardLargeHeading}>{props.cardTitle}</h6>
      <p>{props.cardSubtitle}</p>
      <Link href={props.cardLink ? props.cardLink : '#'}>
        {props.cardLinkText ? props.cardLinkText : 'Create New'}
      </Link>

      {props.showAvatars && (
        <ul className={styles.cardLargeAvatars}>
          {avatars.map((avatar: any, i: any) => {
            const pos = `translate-y-1/2 -translate-x-[${i * 100}px]`;
            return (
              <li className={`${styles.cardLargeAvatarItem} ${pos}`} key={i}>
                <PersonaAvatar id={avatar.avatar_id} linked={true} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
