import { format, parseISO } from 'date-fns';
import type { DateFormatterProps } from 'types';

import styles from './dateFormatter.module.css';

export default function DateFormatter({ dateString }: DateFormatterProps) {
  const date = parseISO(dateString);
  return (
    <div className={styles.wrapper}>
      <time dateTime={dateString}>{format(date, 'LLLL	d, yyyy')}</time>
    </div>
  );
}
