import type { User } from '@supabase/auth-helpers-react';
import {
  useSessionContext,
  useUser as useSupaUser,
} from '@supabase/auth-helpers-react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { Subscription, UserDetails } from 'types';

type UserContextType = {
  accessToken: string | null;
  user: User | null;
  userDetails: UserDetails | null;
  isLoading: boolean;
  subscription: Subscription | null;
  credits: number | any | null;
  avatars: any | null;
  generations: any | null;
  favourites: any | null;
  session: any | null;
};

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

export interface Props {
  [propName: string]: any;
}

export const MyUserContextProvider = (props: Props) => {
  const {
    session,
    isLoading: isLoadingUser,
    supabaseClient: supabase,
  } = useSessionContext();
  const user = useSupaUser();
  const accessToken = session?.access_token ?? null;
  const [isLoadingData, setIsloadingData] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [credits, setCredits] = useState<number | any | null>(null);
  const [avatars, setAvatars] = useState<any | null>(null);
  const [generations, setGenerations] = useState<any | null>(null);
  const [favourites, setFavourites] = useState<any | null>(null);

  // Get users details
  const getUserDetails = () => supabase.from('users').select('*').single();

  // Get users subscription details
  const getSubscription = () =>
    supabase
      .from('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active'])
      .single();

  // Get users credit balance
  const getCredits = () => supabase.from('credits').select('*').single();
  // const getCredits = () =>
  //   supabase
  //     .channel('any')
  //     .on(
  //       'postgres_changes',
  //       { event: 'UPDATE', schema: 'public', table: 'credits' },
  //       (payload) => {
  //         console.log('Payload: ', payload);
  //         // setCredits(payload);
  //       }
  //     )
  //     .subscribe();

  const getAvatars = () => supabase.from('avatars').select('*');

  const getGenerations = () =>
    supabase
      .from('generations')
      .select('*')
      .order('updated_date', { ascending: false });

  const getFavourites = () =>
    supabase
      .from('generations')
      .select('*')
      .in('favorite', [true])
      .order('updated_date', { ascending: false });

  useEffect(() => {
    if (user && !isLoadingData && !userDetails && !subscription) {
      setIsloadingData(true);

      Promise.allSettled([
        getUserDetails(),
        getSubscription(),
        getCredits(),
        getAvatars(),
        getGenerations(),
        getFavourites(),
      ]).then((results) => {
        const userDetailsPromise = results[0];
        const subscriptionPromise = results[1];
        const creditsPromise = results[2];
        const avatarsPromise = results[3];
        const generationsPromise = results[4];
        const favouritesPromise = results[5];

        if (userDetailsPromise.status === 'fulfilled')
          setUserDetails(userDetailsPromise.value.data as UserDetails);

        if (subscriptionPromise.status === 'fulfilled')
          setSubscription(subscriptionPromise.value.data as Subscription);

        if (creditsPromise.status === 'fulfilled')
          setCredits(creditsPromise?.value?.data?.credits as number);

        if (avatarsPromise.status === 'fulfilled')
          setAvatars(avatarsPromise.value.data);

        if (generationsPromise.status === 'fulfilled')
          setGenerations(generationsPromise.value.data);

        if (favouritesPromise.status === 'fulfilled')
          setFavourites(favouritesPromise?.value.data);

        setIsloadingData(false);
      });
    } else if (!user && !isLoadingUser && !isLoadingData) {
      setUserDetails(null);
      setSubscription(null);
      setCredits(null);
      setAvatars(null);
      setGenerations(null);
      setFavourites(null);
    }
  }, [user, isLoadingUser]);

  const value = {
    accessToken,
    user,
    session,
    userDetails,
    isLoading: isLoadingUser || isLoadingData,
    subscription,
    credits,
    avatars,
    generations,
    favourites,
  };

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a MyUserContextProvider.`);
  }
  return context;
};
// function avatar_id(avatar_id: any, id: any) {
//   throw new Error('Function not implemented.');
// }
