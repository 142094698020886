import Link from 'next/link';
import type { PricingAltProps } from 'types';

import styles from './pricing.module.css';
import PricingCard from './pricingCard';

export default function PricingAlt({ products, version }: PricingAltProps) {
  const content = {
    title: 'Choose the plan that works for you',
    subtitle: 'Pricing',
  };

  return (
    <section id="pricing" className={styles.wrapper}>
      <div className={styles.inner}>
        <span className={styles.subtitle}>{content.subtitle}</span>
        {version === 'page' ? (
          <h1 className={styles.title}>{content.title}</h1>
        ) : (
          <h2 className={styles.title}>{content.title}</h2>
        )}
        <p className={styles.intro}>
          We offer 3 tiers of pricing, please select the tier that best suits
          your needs. If you have any questions, please{' '}
          <Link href="/contact">contact us</Link>.
        </p>

        <div className={styles.table}>
          {products?.map((product: any, i: number) => (
            <PricingCard key={i} content={product} />
          ))}
        </div>
      </div>
    </section>
  );
}
