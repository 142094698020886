/* eslint-disable @typescript-eslint/no-use-before-define */
import CardLarge from 'components/cards/cardLarge';
import Icon from 'components/ui/icons';
// import { useAppContext } from 'hooks/useAppContext';
import Link from 'next/link';
import type { MenuItemProps } from 'types';
import { useUser } from 'utils/useUser';

import styles from './menuItem.module.css';
import PersonaMenuContent from './personas/personaMenu';

const Counter = ({ count }: { count: number }) => {
  return <div className={styles.menuItemCounter}>{count}</div>;
};

export default function MenuItem(props: MenuItemProps) {
  const { avatars, generations, favourites } = useUser();

  let counter = 0;

  // console.log('👤 Sidebar Avatars: ', avatars);
  if (props.counter === 'favourites') {
    counter = favourites.length;
  } else if (props.counter === 'generations') {
    counter = generations.length;
  }

  // console.log('PAGE: ', props.titleIconLink);
  // console.log('ICON: ', props.titleIcon.kind);

  return (
    <li className={styles.menuItem} data-menu-item>
      <div className={styles.menuItemUpper}>
        <Link href={props.titleIconLink}>
          <Icon
            name={props.titleIcon.name}
            framed={props.titleIcon.framed}
            ttText={props.titleIcon.ttText}
            ttDir={props.titleIcon.ttDir}
            kind={props.titleIcon.kind}
          />
        </Link>
        {props.sidebarState === 'open' && (
          <>
            <h6 className={styles.menuItemTitle}>{props.title}</h6>
            <div className={styles.menuItemCounterBtn}>
              {counter > 0 && <Counter count={counter} />}
              {props.titleIconLink === '/' ? (
                <Link href={props.titleIconLink}>
                  <button
                    className={styles.menuItemActionBtn}
                    onClick={props.action}
                  >
                    {!props.active ? (
                      <Icon
                        name={props.actionIcon.name}
                        framed={props.actionIcon.framed}
                        ttText={props.actionIcon.ttText}
                        ttDir={props.actionIcon.ttDir}
                        kind={props.actionIcon.kind}
                      />
                    ) : (
                      <Icon
                        name={props.activeIcon.name}
                        framed={props.activeIcon.framed}
                        ttText={props.activeIcon.ttText}
                        ttDir={props.activeIcon.ttDir}
                        kind={props.activeIcon.kind}
                      />
                    )}
                  </button>
                </Link>
              ) : (
                <button
                  className={styles.menuItemActionBtn}
                  onClick={props.action}
                >
                  {!props.active ? (
                    <Icon
                      name={props.actionIcon.name}
                      framed={true}
                      ttText={props.actionIcon.ttText}
                      ttDir={props.actionIcon.ttDir}
                      kind={'sidebar-primary-no-borders'}
                    />
                  ) : (
                    <Icon
                      name={props.activeIcon.name}
                      framed={true}
                      ttText={props.activeIcon.ttText}
                      ttDir={props.activeIcon.ttDir}
                      kind={'sidebar-primary-no-borders'}
                    />
                  )}
                </button>
              )}
            </div>
          </>
        )}
      </div>

      {props.cardContent &&
        props.active &&
        props.sidebarState === 'open' &&
        (props.isPersona ? (
          <PersonaMenuContent data={avatars} />
        ) : (
          <div className={styles.menuItemLower}>
            <CardLarge
              cardTitle={props.cardContent.cardTitle}
              cardSubtitle={props.cardContent.cardSubtitle}
              cardLinkText={props.cardContent.cardLinkText}
              cardLink={props.cardContent.cardLink}
            />
          </div>
        ))}

      {props.active && props.sidebarState === 'open' && props.children}
    </li>
  );
}
