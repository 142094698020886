import { useSupabaseClient } from '@supabase/auth-helpers-react';
import Icon from 'components/ui/icons';
import Locale from 'locale/lang';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useUser } from 'utils/useUser';

import styles from './accountToggle.module.css';

interface AccountToggleProps {}

const UserInitials = ({ action }: any) => {
  const { user } = useUser();
  const userInitials = user?.email?.substring(0, 2);

  return (
    <button className={styles.initials} onClick={action}>
      {userInitials}
    </button>
  );
};

const UserPicture = ({ current }: any) => {
  const userPictureAlt = current?.email as string;
  const userPicture = current?.user_metadata?.avatar_url;

  return current ? (
    <div className="h-9 w-9 overflow-hidden rounded-full border-2 border-white/10 bg-cover shadow-lg shadow-black/20">
      <Image src={userPicture} width={48} height={48} alt={userPictureAlt} />
    </div>
  ) : null;
};

export default function AccountToggle(_props: AccountToggleProps) {
  const supabaseClient = useSupabaseClient();
  const router = useRouter();

  const { session, userDetails } = useUser();

  // console.log(userDetails);

  const lang = 'en';
  const { txt } = Locale({ lang });

  const [isAccountExpanded, setIsAccountExpanded] = useState(false);

  const accountAccordionHandler = () => {
    setIsAccountExpanded((curState) => !curState);
  };

  const accountToggleClasses = !isAccountExpanded
    ? `${styles.accountToggleBtn}`
    : `${styles.accountToggleBtn} ${styles.accountToggleBtnActive}`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.upper} onClick={accountAccordionHandler}>
        <span>
          {session ? <UserPicture current={session.user} /> : <UserInitials />}
          <h6 className="flex flex-row items-center justify-center gap-4">
            {txt.sideBar.account}{' '}
            {userDetails?.store_name && (
              <span className="relative top-[0.5px] font-inter text-xs font-semibold text-white/50">
                {userDetails.store_name}
              </span>
            )}
          </h6>
        </span>
        <button className={accountToggleClasses}>
          <Icon name="chevron" />
        </button>
      </div>
      {isAccountExpanded && (
        <div className={styles.lower}>
          <ul>
            <li>
              <Link href="/settings">{txt.sideBar.settings}</Link>
            </li>
            <li className={styles.accountToggleListItem}>
              <span
                onClick={async () => {
                  await supabaseClient.auth.signOut();
                  router.push('/signin');
                }}
              >
                {txt.account.logout}
              </span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
