/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import Logo from 'components/icons/Logo';
import LogoFull from 'components/icons/LogoFull';
import USP from 'components/marketing/usp';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import styles from './nav.module.css';

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const router = useRouter();

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const heightToHideFrom = 300;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  let hideNav = false;
  let navMessage = '';
  let urlPrefix = '/';

  if (router.pathname === '/') {
    urlPrefix = '';
  } else if (router.pathname === '/signin') {
    hideNav = true;
    navMessage = "Don't have an account?";
  } else if (router.pathname === '/signup') {
    hideNav = true;
    navMessage = 'Already using Descripti?';
  }

  return (
    <>
      <a href="#skip" className="sr-only focus:not-sr-only">
        Skip to content
      </a>
      <USP visible={isVisible} />
      <header className={`${styles.wrapper} ${!isVisible && styles.hide}`}>
        <nav className={styles.inner} aria-label="Global">
          <div className={styles.logoWrapper}>
            <span className="sr-only">Descripti.ai</span>
            <div className={styles.fullLogo}>
              <Link href="/">
                <LogoFull />
              </Link>
            </div>
            <div className={styles.dotsLogo}>
              <Link href="/">
                <Logo />
              </Link>
            </div>
          </div>

          <div
            className={`${styles.nav} ${
              isOpen ? styles.activeMenu : styles.inactiveMenu
            }`}
          >
            <div className={`${hideNav ? 'lg:hidden' : 'flex flex-col'}`}>
              <div className={styles.navLinks}>
                <a href={`${urlPrefix}#features`} onClick={handleMenuToggle}>
                  Features
                </a>
                <Link href="/pricing" onClick={handleMenuToggle}>
                  Pricing
                </Link>
                <Link href="/blog" onClick={handleMenuToggle}>
                  Blog
                </Link>
                <a href={`${urlPrefix}#faq`} onClick={handleMenuToggle}>
                  FAQs
                </a>
              </div>

              <div className="flex items-center gap-3 lg:hidden">
                {navMessage && (
                  <div className="text-center text-sm text-gray-500">
                    {navMessage}
                  </div>
                )}
                {router.pathname === '/signin' && (
                  <Link href="/signup" className={styles.buttonFull}>
                    Sign Up
                  </Link>
                )}
                {router.pathname === '/signup' && (
                  <Link href="/signin" className={styles.buttonFull}>
                    Log in
                  </Link>
                )}
                {router.pathname !== '/signin' &&
                  router.pathname !== '/signup' && (
                    <>
                      <Link href="/signin" className={styles.buttonBorder}>
                        Log in
                      </Link>
                      <Link href="/signup" className={styles.buttonFull}>
                        Sign Up
                      </Link>
                    </>
                  )}
              </div>
            </div>
          </div>

          <div className="hidden items-center gap-3 lg:flex">
            {navMessage && (
              <div className="text-center text-sm text-gray-500">
                {navMessage}
              </div>
            )}
            {router.pathname === '/signin' && (
              <Link href="/signup" className={styles.buttonFull}>
                Sign Up
              </Link>
            )}
            {router.pathname === '/signup' && (
              <Link href="/signin" className={styles.buttonFull}>
                Log in
              </Link>
            )}
            {router.pathname !== '/signin' && router.pathname !== '/signup' && (
              <>
                <Link href="/signin" className={styles.buttonBorder}>
                  Log in
                </Link>
                <Link href="/signup" className={styles.buttonFull}>
                  Sign Up
                </Link>
              </>
            )}
          </div>

          <button
            type="button"
            className={styles.burger}
            onClick={handleMenuToggle}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </nav>
      </header>
    </>
  );
}
