/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import Link from 'next/link';
import { useEffect, useState } from 'react';

import styles from './tableOfContents.module.css';

export default function Toc({ toc, unstuck }: any) {
  const [activeHeading, setActiveHeading] = useState(
    null as string | null | any
  );

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const heightToHideFrom = 375;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      const activeId = Array.from(
        document.querySelectorAll('.prose h2, .prose h3, .prose h4')
      ).find((el) => el.getBoundingClientRect().top > 0)?.id as string;

      setActiveHeading(activeId);
    } else {
      setActiveHeading(null);
    }
  };

  return (
    <div
      className={`${styles.wrapper} ${unstuck ? styles.unstick : styles.stick}`}
    >
      <h6>Table of Contents</h6>
      <ul>
        {toc.map((item: any) => {
          const activeCheck = `user-content-${item.id}`;
          const isActive = activeHeading === activeCheck;

          return (
            <li key={item.id}>
              <Link className={isActive ? styles.active : ''} href={item.slug}>
                {item.text}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
