export default function Email() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 21">
      <path
        fill="#FF6060"
        d="M.97644 3.40422c0-1.34547 1.09073-2.436202 2.43621-2.436202H22.9023c1.3455 0 2.4362 1.090722 2.4362 2.436202V18.0215c0 1.3454-1.0907 2.4362-2.4362 2.4362H3.41265c-1.34548 0-2.43621-1.0908-2.43621-2.4362V3.40422Zm4.28601 0 7.89505 6.90818 7.895-6.90818H5.26245ZM22.9023 5.0228l-8.9427 7.8249c-.4593.4018-1.145.4018-1.6043 0L3.41265 5.0228v12.9987H22.9023V5.0228Z"
      />
    </svg>
  );
}
