import KarmoonLogo from 'components/icons/karmoon';
import LogoText from 'components/icons/LogoText';
import Link from 'next/link';

import styles from './footer.module.css';

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className={styles.wrapper}>
      <div className={styles.inner}>
        <Link href="/">
          <LogoText />
        </Link>

        <ul>
          <li>
            <Link href="/#features">Features</Link>
          </li>
          <li>
            <Link href="/pricing">Pricing</Link>
          </li>
          <li>
            <Link href="/help">Help</Link>
          </li>
          <li>
            <Link href="/contact">Contact</Link>
          </li>
          <li>
            <Link href="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link href="/cookies">Cookies</Link>
          </li>
        </ul>

        <p className="text-xs">&copy; {year} Karmoon. All rights reserved.</p>

        <a href="https://karmoon.co.uk" aria-label="Karmoon">
          <div className={styles.kLogo}>
            <KarmoonLogo />
          </div>
        </a>
      </div>
    </footer>
  );
}
