/* eslint-disable import/no-cycle */
import { Author, CoverImage, DateFormatter } from 'components/blog';
import Link from 'next/link';
import type { HeroArticleProps } from 'types';

import styles from './heroArticle.module.css';

export default function HeroArticle({ content }: HeroArticleProps) {
  const { title, coverImage, coverBg, date, excerpt, author, slug } = content;

  return (
    <section className={styles.wrapper}>
      <div className={styles.imageContainer}>
        <CoverImage title={title} src={coverImage} slug={slug} bg={coverBg} />
      </div>
      <div className={styles.textContainer}>
        <Link href={`/blog/${slug}`} className="no-underline hover:underline">
          <h2>{title}</h2>
        </Link>

        <DateFormatter dateString={date} />

        <p className={styles.excerpt}>{excerpt}</p>

        <Author content={author} />
      </div>
    </section>
  );
}
