import { useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from './mailingList.module.css';

export default function MailingList() {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const octopusListID = process.env.OCTOPUS_LIST_ID;

  const handleAccept = () => {
    setIsAccepted(!isAccepted);
  };

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const hasAgreed = watch('acceptUpdates');

  const resetForm = (e: any) => {
    e.preventDefault();
    reset();
    setError(false);
    setSuccess(false);
    setSubmitting(false);
    setIsAccepted(false);
  };

  const email = register('email', {
    required: 'This field is required.',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address.',
    },
  });

  const fullname = register('fullname');

  function onSubmit(data: any, event: any) {
    event.preventDefault();

    if (!octopusListID) setError(true);
    if ((!hasAgreed && !octopusListID) || !octopusListID) return;

    setSubmitting(true);
    setError(false);

    // window.analytics.track('Mailing List Signup', {
    //   octopusListID,
    // })

    fetch('/api/octopus/subscribe/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        listID: octopusListID,
        email: data.email,
        fullname: data.fullname,
        acceptTerms: data.acceptUpdates,
        tags: ['landing-page'],
      }),
    })
      .then((res) => res.json())
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
      })
      .catch(() => {
        setSubmitting(false);
        setError(true);
        console.log(error);
      });
  }

  const SuccessMessage = () => {
    return (
      <div className="success-msg">
        <h4 className="form-alert mt-5">Success!</h4>
        <p className="mt-1">We are delighted to have you with us.</p>
      </div>
    );
  };

  const ErrorMessage = () => {
    return (
      <div className="error-msg">
        <h4 className="form-alert mt-5">Error!</h4>
        <p className="form-message mt-2">
          Looks like you are already subscribed.
        </p>
        <button
          className="button button--secondary mt-4"
          onClick={(e) => resetForm(e)}
        >
          Try again
        </button>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <p className={styles.subtitle}>Newsletter</p>
        <h4 className={styles.title}>Get notified about future updates</h4>

        <div className="w-full">
          {submitting && <div>Sending...</div>}

          {success && <SuccessMessage />}

          {error && <ErrorMessage />}

          {!success && (
            // NOTE: Form will hide completely on successful submission

            <form
              action=""
              className={styles.cta}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.ctaInputWrapper}>
                <input
                  type="text"
                  name="fullname"
                  autoComplete="off"
                  ref={fullname.ref}
                  aria-hidden="true"
                  className="hidden"
                  onBlur={(e) => {
                    fullname.onBlur(e);
                  }}
                  onChange={(e) => {
                    fullname.onChange(e);
                  }}
                />
                <label className="hidden" htmlFor="email">
                  Enter your email:
                </label>
                <input
                  name="email"
                  type="email"
                  placeholder="email@descripti.ai"
                  inputMode="email"
                  autoComplete="email"
                  ref={email.ref}
                  className={styles.ctaInput}
                  id="email"
                  required
                  onBlur={(e) => {
                    email.onBlur(e);
                  }}
                  onChange={(e) => {
                    email.onChange(e);
                  }}
                />
                {errors?.email && (
                  <span
                    role="alert"
                    id="error-message"
                    className="absolute right-[100px] top-2/4 translate-y-[-50%] text-xs text-[red]"
                  >
                    {/* {errors.email.message} */}
                    Invalid email address.
                  </span>
                )}
                <button
                  type="submit"
                  className={`button button--secondary ${styles.ctaSubmitDesktop}`}
                  data-desktop-submit-btn
                >
                  Sign Up
                </button>
              </div>
              <button
                type="submit"
                className={`button button--secondary ${styles.ctaSubmitMobile}`}
                data-mobile-submit-btn
              >
                Sign Up
              </button>
              <div className={styles.ctaConsent}>
                <input
                  // name="acceptUpdates"
                  type="checkbox"
                  id="accept-updates"
                  onClick={handleAccept}
                  {...register('acceptUpdates')}
                ></input>
                <label htmlFor="accept-updates">
                  I confirm I am happy to receive updates about descripti.ai
                </label>
                {/* <span className={styles.ctaConsentCustom}></span> */}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
