import PersonaAvatar from 'components/ui/avatar';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { Avatar } from 'types';

import styles from './cardPersona.module.css';

interface CardPersonaProps {
  flipColours?: boolean;
  key?: string;
  edit?: boolean;
  data: Avatar;
}

export default function CardPersona(props: CardPersonaProps) {
  const {
    avatar_name: avatarName,
    gender,
    min_age: minAge,
    max_age: maxAge,
    selected,
    avatar_id: id,
  } = props.data || {};

  const router = useRouter();

  return (
    <div
      className={`
      ${!props.flipColours ? styles.cardPersona : styles.cardPersonaFlipped}
      ${selected ? styles.cardPersonaSelected : ''}
      `}
    >
      <div className="flex gap-4">
        <PersonaAvatar id={id} />
        <div>
          <h6 className={styles.cardPersonaTitle}>{avatarName}</h6>
          <p
            className={styles.cardPersonaDetails}
          >{`${minAge} - ${maxAge} | ${gender}`}</p>
          {props.edit !== false && (
            <Link href={`/persona/update/${id}`}>
              <button
                className={`button button--link ${styles.cardPersonaEdit}`}
              >
                {router.query.id === id ? 'Editing...' : 'Edit'}
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
