/* eslint-disable no-nested-ternary */
import { MarketingNav } from 'components/marketing';
import Footer from 'components/ui/footer';
import Navbar from 'components/ui/navBar';
import Sidebar from 'components/ui/sidebar';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useUser } from 'utils/useUser';

import type { PageMeta } from '../types';

interface Props {
  children: ReactNode;
  meta?: PageMeta;
}

export default function Layout({ children, meta: pageMeta }: Props) {
  const router = useRouter();

  const rootUrl = `${process.env.NEXT_PUBLIC_SITE_URL}`;
  let pageUrl = router.asPath === '/' ? '' : router.asPath;
  pageUrl = pageUrl.replace(/^\/+/, '');

  const meta = {
    title: 'AI Content Creation for Ecommerce | Descripti.ai',
    description:
      'Generate product titles, descriptions and metadata in seconds! Try Descripti.ai today to see how our AI tools transform the way you write your product content.',
    cardImage: `${rootUrl}og.png`,
    canonical: `${rootUrl}${pageUrl}`,
    ...pageMeta,
  };

  const { user, subscription } = useUser();

  return (
    <>
      <Head>
        <title>{meta.title}</title>

        <meta name="robots" content="follow, index" />
        <meta content={meta.description} name="description" />

        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="icon" type="image/png" href="/favicon.png" />

        <meta property="og:url" content={meta.canonical} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.cardImage} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@descriptiai" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.cardImage} />

        <meta name="google" content="nositelinkssearchbox" key="sitelinks" />
        <meta name="google" content="notranslate" key="notranslate" />

        <link rel="canonical" href={meta.canonical} />
      </Head>

      {user ? (
        subscription ? (
          <div className="flex h-screen w-screen overflow-hidden">
            <Sidebar />
            <main className="flex w-full flex-col overflow-y-auto">
              {children}
            </main>
          </div>
        ) : (
          <>
            <Navbar />
            <div id="skip">{children}</div>
            <Footer />
          </>
        )
      ) : (
        <>
          <MarketingNav />
          <div id="skip">{children}</div>
          <Footer />
        </>
      )}
    </>
  );
}
