import Locale from 'locale/lang';
import Link from 'next/link';
import { useState } from 'react';
import { useUser } from 'utils/useUser';

import styles from './credits.module.css';

export default function Credits() {
  const lang = 'en';
  const { txt } = Locale({ lang });

  const [isLoading, setIsLoading] = useState(true);
  const [displayedCredits, setDisplayedCredits] = useState(0);

  const { user, subscription, credits } = useUser();

  if (user !== null && credits && isLoading) {
    setDisplayedCredits(credits);
    setIsLoading(false);
  }

  const userId = user?.id;

  let message = '';

  const handleCreditUsage = async (e: any, cost: number) => {
    e.preventDefault();

    const payload = {
      user_id: userId,
      amount: cost,
    };

    const response = await fetch('/api/use-credits', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ payload }),
    });

    const data = await response.json();
    const { error } = data;

    if (error) {
      console.log(error);
    } else {
      // console.log(data.data);
      setDisplayedCredits(data.data);
    }
  };

  if (!subscription) {
    message = `${txt.credits.subscription.inactive} ${(
      <Link href="/pricing">here</Link>
    )}`;
  } else {
    const tier = subscription?.prices?.products?.name;
    message = `You are on the ${tier} tier and have ${displayedCredits} remaining this month`;
  }

  // console.log('Loading? ', isLoading);

  return (
    <div className={styles.wrapper}>
      <p className="m-0 w-full p-0 text-left text-xs text-white">{message}</p>

      <p
        className="mt-4 cursor-pointer text-left text-xs font-bold text-white"
        onClick={(e) => handleCreditUsage(e, 10)}
      >
        Use Credits (10)
      </p>
    </div>
  );
}
