export default function Blog() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 28">
      <path
        fill="#4A1F80"
        d="M4.68042 4.66659c0-1.28867 1.04467-2.33334 2.33333-2.33334h9.33335c.3094 0 .6062.12292.8249.34171l5.8334 5.83333c.2188.2188.3417.51554.3417.82496V23.3333c0 1.2886-1.0447 2.3333-2.3333 2.3333H7.01375c-1.28866 0-2.33333-1.0447-2.33333-2.3333V4.66659ZM20.5305 9.33325l-4.1834-4.18342v4.18342h4.1834Zm-6.5167-4.66666H7.01375V23.3333H21.0138V11.6666h-5.8334c-.6443 0-1.1666-.5223-1.1666-1.1667V4.66659ZM9.34709 15.1666c0-.6443.52233-1.1667 1.16671-1.1667h7c.6443 0 1.1666.5224 1.1666 1.1667 0 .6443-.5223 1.1667-1.1666 1.1667h-7c-.64438 0-1.16671-.5224-1.16671-1.1667Zm0 4.6667c0-.6444.52233-1.1667 1.16671-1.1667h7c.6443 0 1.1666.5223 1.1666 1.1667 0 .6443-.5223 1.1666-1.1666 1.1666h-7c-.64438 0-1.16671-.5223-1.16671-1.1666Z"
      />
    </svg>
  );
}
