/* eslint-disable no-plusplus */
import { ArticleCard } from 'components/blog';
import Link from 'next/link';

import styles from './blogPosts.module.css';

type Props = {
  articles: any;
};

export default function BlogPosts({ articles }: Props) {
  const content = {
    title: 'Read more about Descripti.ai',
    subtitle: 'Blog',
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <span className={styles.subtitle}>{content.subtitle}</span>
        <h2 className={styles.title}>{content.title}</h2>
        <p className={styles.intro}>
          We offer 3 tiers of pricing, please select the tier that best suits
          your needs. If you have any questions, please{' '}
          <Link href="/contact">contact us</Link>.
        </p>

        <div className={styles.blogGrid}>
          {articles.map((article: any, i: number) => {
            if (i <= 2) {
              return (
                <ArticleCard
                  key={article.slug}
                  content={article}
                  showAuthor={false}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}
