export default function Instagram() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
      <g fill="#000100" clipPath="url(#a)">
        <path d="M12.5971 3.0768c3.1409 0 3.5128.01377 4.748.06888 1.148.05051 1.7679.24336 2.1811.40408.5465.21122.9414.46836 1.35.87704.4133.41326.6658.80357.8771 1.35.1607.41326.3535 1.03775.4041 2.18112.0551 1.2398.0688 1.61174.0688 4.74798 0 3.1408-.0137 3.5127-.0688 4.7479-.0506 1.148-.2434 1.7679-.4041 2.1812-.2113.5464-.4684.9413-.8771 1.35-.4132.4132-.8035.6658-1.35.877-.4132.1607-1.0377.3536-2.1811.4041-1.2398.0551-1.6117.0689-4.748.0689-3.14077 0-3.51271-.0138-4.74791-.0689-1.14796-.0505-1.76786-.2434-2.18113-.4041-.54643-.2112-.94132-.4684-1.35-.877-.41326-.4133-.66581-.8036-.87704-1.35-.16071-.4133-.35357-1.0378-.40408-2.1812-.0551-1.2398-.06888-1.6117-.06888-4.7479 0-3.14084.01378-3.51278.06888-4.74798.05051-1.14796.24337-1.76786.40408-2.18112.21123-.54643.46837-.94133.87704-1.35.41327-.41327.80357-.66582 1.35-.87704.41327-.16072 1.03776-.35357 2.18113-.40408 1.2352-.05511 1.60714-.06888 4.74791-.06888Zm0-2.116839c-3.19128 0-3.59077.013775-4.84434.068879-1.24898.0551-2.10766.25714-2.85153.54643-.77603.30306-1.43266.70255-2.0847 1.35918-.65663.65204-1.05612 1.30867-1.35918 2.0801-.28929.74847-.491329 1.60255-.546431 2.85153C.855817 9.12425.842041 9.52374.842041 12.7151c0 3.1913.013776 3.5908.068878 4.8444.055102 1.2489.257141 2.1076.546431 2.8515.30306.776.70255 1.4326 1.35918 2.0847.65204.652 1.30867 1.0561 2.0801 1.3546.74847.2893 1.60255.4913 2.85153.5464 1.25358.0551 1.65307.0689 4.84444.0689 3.1913 0 3.5908-.0138 4.8443-.0689 1.249-.0551 2.1077-.2571 2.8516-.5464.7714-.2985 1.428-.7026 2.0801-1.3546.652-.6521 1.0561-1.3087 1.3546-2.0801.2893-.7485.4913-1.6026.5464-2.8516.0551-1.2535.0689-1.653.0689-4.8443 0-3.19137-.0138-3.59086-.0689-4.84443-.0551-1.24898-.2571-2.10766-.5464-2.85153-.2893-.78062-.6888-1.43725-1.3454-2.08929-.6521-.65204-1.3087-1.05612-2.0801-1.35459-.7485-.28929-1.6026-.49133-2.8516-.54643-1.2581-.059694-1.6576-.073469-4.849-.073469Z" />
        <path d="M12.5972 6.67676c-3.33365 0-6.03824 2.70459-6.03824 6.03824 0 3.3337 2.70459 6.0383 6.03824 6.0383 3.3337 0 6.0383-2.7046 6.0383-6.0383 0-3.33365-2.7046-6.03824-6.0383-6.03824Zm0 9.95514c-2.1627 0-3.91681-1.7541-3.91681-3.9169 0-2.1627 1.75411-3.91681 3.91681-3.91681 2.1628 0 3.9169 1.75411 3.9169 3.91681 0 2.1628-1.7541 3.9169-3.9169 3.9169ZM20.284 6.43802c0 .78061-.6337 1.40969-1.4097 1.40969-.7806 0-1.4097-.63367-1.4097-1.40969 0-.78062.6337-1.4097 1.4097-1.4097.776 0 1.4097.63368 1.4097 1.4097Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.376221.959961h24v23.5102h-24z" />
        </clipPath>
      </defs>
    </svg>
  );
}
