export default function TikTok() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24">
      <path
        fill="#FF004F"
        d="M18.114 8.66347c1.5433 1.10717 3.434 1.75863 5.476 1.75863V6.47861c-.3864.00008-.7719-.04036-1.15-.12075v3.10407c-2.0419 0-3.9323-.65144-5.476-1.75853v8.0475c0 4.0257-3.252 7.289-7.26317 7.289-1.49667 0-2.88777-.4541-4.04336-1.2329C6.97638 23.1604 8.81571 24 10.8506 24c4.0114 0 7.2635-3.2633 7.2635-7.2892V8.66347h-.0001Zm1.4186-3.97848c-.7887-.86476-1.3066-1.98232-1.4186-3.21782V.959961h-1.0898c.2743 1.570319 1.2099 2.911909 2.5084 3.725029ZM8.19456 18.7178c-.44068-.5799-.67882-1.2892-.67775-2.0186 0-1.8412 1.48741-3.3341 3.32249-3.3341.342-.0001.6819.0525 1.0078.1563V9.48977c-.3809-.05238-.7653-.07462-1.1495-.06647v3.138c-.3262-.1038-.6663-.1565-1.00833-.1562-1.83506 0-3.32239 1.4928-3.32239 3.3342 0 1.3021.74346 2.4294 1.82768 2.9785Z"
      />
      <path
        fill="#000"
        d="M16.964 7.70332c1.5437 1.10709 3.4342 1.75853 5.476 1.75853V6.35778c-1.1397-.24364-2.1487-.84138-2.9074-1.67279-1.2985-.8132-2.2341-2.15479-2.5084-3.725029h-2.8626V16.7106c-.0065 1.8362-1.4914 3.323-3.3225 3.323-1.07903 0-2.03766-.5162-2.64478-1.3158-1.08413-.5491-1.82759-1.6765-1.82759-2.9784 0-1.8413 1.48732-3.3342 3.32238-3.3342.35159 0 .69049.055 1.00839.1562V9.42338c-3.94079.08171-7.1101 3.31312-7.1101 7.28732 0 1.9839.78923 3.7824 2.07016 5.0964 1.15559.7787 2.54669 1.2329 4.04336 1.2329 4.01128 0 7.26308-3.2635 7.26308-7.2891V7.70332Z"
      />
      <path
        fill="#00F2EA"
        d="M22.44 6.3578v-.83932c-1.0277.00157-2.0353-.28729-2.9073-.83355.7719.84813 1.7883 1.43294 2.9073 1.67287ZM17.0242.959983c-.0262-.150072-.0463-.301132-.0602-.452769V0h-3.9525v15.7508c-.0063 1.836-1.4911 3.3228-3.32236 3.3228-.53764 0-1.04526-.1281-1.49479-.3557.60712.7995 1.56574 1.3157 2.64475 1.3157 1.831 0 3.3161-1.4867 3.3225-3.3229V.959983h2.8626ZM10.6976 9.42341v-.89352c-.3302-.0453-.6632-.06803-.99657-.06787C5.68942 8.46194 2.4375 11.7254 2.4375 15.7508c0 2.5237 1.27806 4.7479 3.22017 6.0562-1.28093-1.314-2.07016-3.1126-2.07016-5.0964 0-3.9741 3.16923-7.20549 7.11009-7.28719Z"
      />
    </svg>
  );
}
