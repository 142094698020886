import s from './LoadingDots.module.css';

interface LoadingDotsProps {
  version?: 'straight' | 'spin';
  colour: boolean;
}

const LoadingDots = ({ version, colour }: LoadingDotsProps) => {
  switch (version) {
    case 'spin':
      return (
        <span className={`${s.root} ${s.spin} ${colour ? s.colour : s.mono}`}>
          <span />
          <span />
          <span />
          <span />
        </span>
      );
    default:
      return (
        <span
          className={`${s.root} ${s.straight} ${colour ? s.colour : s.mono}`}
        >
          <span />
          <span />
          <span />
          <span />
        </span>
      );
  }
};

export default LoadingDots;
