/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import 'swiper/css';
import 'swiper/css/pagination';

import CardPersona from 'components/cards/cardPersona';
import Icon from 'components/ui/icons';
import LoadingDots from 'components/ui/LoadingDots';
import Link from 'next/link';
import { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './personaMenu.module.css';

interface PersonaMenuContentProps {
  data: any;
}

export default function PersonaMenuContent({ data }: PersonaMenuContentProps) {
  const sortedAvatars = data?.sort((a: any, b: any) => {
    return b?.selected - a?.selected;
  });

  return sortedAvatars.length > 0 ? (
    <div className={styles.wrapper}>
      <Swiper
        className={styles.swiper}
        modules={[Pagination, A11y]}
        spaceBetween={15}
        slidesPerView={
          sortedAvatars?.length <= 1
            ? 1
            : sortedAvatars?.length === 2
            ? 1.25
            : 1.25
        }
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        effect={'cards'}
        pagination={{ clickable: true, dynamicBullets: true }}
      >
        {sortedAvatars.map((avatar: any) => {
          return (
            <SwiperSlide key={avatar?.avatar_id}>
              <CardPersona data={avatar} />
            </SwiperSlide>
          );
        })}
        <SwiperSlide key="add new button" className={styles.addButton}>
          <Link href="/persona/create">
            <Icon
              name="expand"
              framed={true}
              kind="sidebar-primary-no-borders"
            ></Icon>
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  ) : (
    <i className="m-0 flex pl-2">
      <LoadingDots colour={false} version={'straight'} />
    </i>
  );
}
