import getReadTime from 'utils/read-time';

import styles from './readTime.module.css';

export default function ReadTime({ content }: any) {
  const readTime = getReadTime(content);
  return (
    <div className={styles.wrapper}>
      <p>{readTime} min read</p>
    </div>
  );
}
