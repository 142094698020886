import CardMenu from 'components/cards/cardMenu';
import CardSmall from 'components/cards/cardSmall';
import Generations from 'components/ui/sidebar/generations';
import Locale from 'locale/lang';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useFlow } from 'utils/useFlow';

import Icon from '../icons';
import styles from './menu.module.css';
import MenuItem from './menuItem';

interface MenuProps {
  sidebarState?: 'open' | 'closed';
}

function returnIconData(
  name: string,
  ttText: string,
  ttDir: 'left' | 'right' | 'top' | 'bottom' = 'right',
  kind:
    | 'dashboard-primary'
    | 'dashboard-secondary'
    | 'sidebar-primary'
    | 'sidebar-primary-no-borders'
    | 'sidebar-secondary' = 'sidebar-primary-no-borders',
  framed: boolean = true
) {
  return {
    name,
    framed,
    kind,
    ttText,
    ttDir,
  };
}

export default function Menu(props: MenuProps) {
  const router = useRouter();
  const [activeItem, setActiveItem] = useState(1);
  const [activeIcon, setActiveIcon] = useState(1);
  const [flowData, setFlowData] = useState<any>({});

  const { flow } = useFlow();

  const lang = 'en';
  const { txt } = Locale({ lang });

  function handleMenuItemClick(id: number) {
    if (activeItem === id) {
      setActiveItem(0);
    } else {
      setActiveItem(id);
    }
  }

  useEffect(() => {
    switch (router.pathname) {
      case '/persona':
        setActiveIcon(2);
        break;
      case '/generate':
        setActiveIcon(3);
        break;
      case '/g':
        setActiveIcon(4);
        break;
      case '/g/f':
        setActiveIcon(5);
        break;
      default:
        setActiveIcon(1);
    }
  }, [router.pathname]);

  useEffect(() => {
    const loadSaved = async () => {
      setFlowData(JSON.parse(localStorage.getItem('currentFlow')!));
    };
    loadSaved();
  }, [flow]);

  // console.log('ls in sidebar', flowData);

  return (
    <nav className={styles.menu}>
      <ul>
        <MenuItem
          key="1"
          sidebarState={props.sidebarState}
          active={activeItem === 1}
          title={txt.sideBar.dashboard}
          isPersona={false}
          titleIconLink="/"
          titleIcon={returnIconData(
            'dashboard',
            'Go to Dashboard',
            'right',
            activeIcon === 1 ? 'sidebar-secondary' : 'sidebar-primary'
          )}
          actionIcon={returnIconData('chevron', 'Go to Dashboard', 'left')}
          activeIcon={returnIconData('chevron', 'Go to Dashboard', 'left')}
          cardContent={null}
          action={() => handleMenuItemClick(1)}
        />

        <MenuItem
          key="2"
          sidebarState={props.sidebarState}
          active={activeItem === 2}
          isPersona={true}
          title={txt.sideBar.avatars}
          titleIconLink="/persona"
          titleIcon={returnIconData(
            'persona',
            'Show user avatars',
            'right',
            activeIcon === 2 ? 'sidebar-secondary' : 'sidebar-primary'
          )}
          actionIcon={returnIconData('expand', 'Expand user avatars', 'left')}
          activeIcon={returnIconData(
            'collapse',
            'Collapse user avatars',
            'left'
          )}
          cardContent={{
            cardTitle: 'Users Avatars 🦸‍♀',
            cardSubtitle:
              'Create your ideal customer Avatar for targeted content',
            cardLinkText: 'Set up now',
            cardLink: '#',
            avatarUrls: '',
          }}
          action={() => handleMenuItemClick(2)}
        />

        <div className={styles.menuNewProduct}>
          {props.sidebarState === 'open' && (
            <li className={styles.menuNewProductCard}>
              <CardSmall
                cardTitle={
                  flowData?.stage
                    ? 'Continue Product Flow'
                    : '⚡ Start a product flow'
                }
                cardLinkText={flowData?.stage ? 'Continue' : 'Create Now'}
                cardLinkDesc="Experience the full power of descripti.ai!"
                productFlow={true}
                cardLink="/generate/flow"
              />
            </li>
          )}

          {props.sidebarState === 'closed' && (
            <li className={styles.menuNewProductBtn}>
              <Link href="/generate/flow">
                <Icon
                  name="expand"
                  framed={true}
                  ttText={
                    flowData?.stage
                      ? 'Continue Product Flow'
                      : 'Create a new product'
                  }
                  ttDir="right"
                  kind="sidebar-secondary"
                />
              </Link>
            </li>
          )}
        </div>

        <MenuItem
          key="3"
          sidebarState={props.sidebarState}
          active={activeItem === 3}
          isPersona={false}
          title={txt.sideBar.tools}
          titleIconLink="/generate"
          titleIcon={returnIconData(
            'tools',
            'Show product tools',
            'right',
            activeIcon === 3 ? 'sidebar-secondary' : 'sidebar-primary'
          )}
          actionIcon={returnIconData('expand', 'Expand product tools', 'left')}
          activeIcon={returnIconData(
            'collapse',
            'Collapse product tools',
            'left'
          )}
          cardContent={{
            cardTitle: 'Full Product Flow ⚡',
            cardSubtitle:
              'Create your ideal customer Avatar for target audience',
            cardLinkText: flowData?.stage ? 'Continue' : 'Create New',

            cardLink: '/generate/flow',
            avatarUrls: '',
          }}
          action={() => handleMenuItemClick(3)}
        >
          <CardMenu
            cardTitle="Product Title"
            cardSubtitle="Single item content"
            cardLink="/generate/titles"
          />
          <CardMenu
            cardTitle="Product Description"
            cardSubtitle="Single item content"
            cardLink="/generate/descriptions"
          />
          <CardMenu
            cardTitle="Product Metadata"
            cardSubtitle="Single item content"
            cardLink="/generate/meta"
          />
        </MenuItem>

        <MenuItem
          key="4"
          sidebarState={props.sidebarState}
          active={activeItem === 4}
          isPersona={false}
          title="Products"
          titleIconLink="/g/"
          titleIcon={returnIconData(
            'products',
            'Show products',
            'right',
            activeIcon === 4 ? 'sidebar-secondary' : 'sidebar-primary'
          )}
          actionIcon={returnIconData('expand', 'Expand products', 'left')}
          activeIcon={returnIconData('collapse', 'Collapse products', 'left')}
          cardContent={null}
          counter="generations"
          action={() => handleMenuItemClick(4)}
        >
          <Generations />
        </MenuItem>

        <MenuItem
          key="5"
          sidebarState={props.sidebarState}
          active={activeItem === 5}
          isPersona={false}
          title="Favorites"
          titleIconLink="/g/f"
          titleIcon={returnIconData(
            'favorites',
            'Show favorites',
            'right',
            activeIcon === 5 ? 'sidebar-secondary' : 'sidebar-primary'
          )}
          actionIcon={returnIconData('expand', 'Expand favorites', 'left')}
          activeIcon={returnIconData('collapse', 'Collapse favorites', 'left')}
          cardContent={null}
          counter="favourites"
          action={() => handleMenuItemClick(5)}
        >
          <Generations t="favourites" />
        </MenuItem>
      </ul>
    </nav>
  );
}
