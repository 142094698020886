/* eslint-disable @typescript-eslint/no-use-before-define */
import { BottomGradient, TopGradient } from 'components/ui/gradients';
import Link from 'next/link';

import { BigCommerce, Shopify, Squarespace, WooCommerce } from '../logos';
import h from './heroAlt.module.css';

export default function HeroAlt() {
  const content = {
    title:
      'The ultimate tool to <span>create product titles <span>and</span> descriptions</span> for ecommerce stores.',
    description:
      'Generate product titles, descriptions and metadata in seconds. Try <strong>descripti.ai</strong> today and see how our AI technology can transform the way you write your product descriptions.',
    button_one: 'Get started',
    button_two: 'Learn more',
  };

  return (
    <div className="relative isolate flex h-[90vh] w-full items-center justify-center bg-white">
      <TopGradient />

      <div className="relative flex h-[90vh] w-full flex-col items-center justify-between px-6 lg:px-8">
        {/* SPACER */}
        <div className={h.heroRow}>&nbsp;</div>

        {/* MAIN CENTRAL CONTENT */}
        <div className={`${h.heroRow} ${h.heroContentWrapper}`}>
          <StatusBox />
          <h1 dangerouslySetInnerHTML={{ __html: content.title }} />
          <p dangerouslySetInnerHTML={{ __html: content.description }} />

          <div className={h.buttonBar}>
            <Link href="/pricing" className={h.buttonFull}>
              Get started
            </Link>
            <Link href="#features" className={h.buttonBorder}>
              Learn more <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>

        {/* LOGO BAR */}
        <div className="mx-auto max-w-5xl">
          <div className={h.logoBar}>
            <p>Easily generate content for any e-commerce platform</p>
            <div className={h.logoBarInner}>
              <BigCommerce />
              <Shopify />
              <WooCommerce />
              <Squarespace />
            </div>
          </div>
        </div>
      </div>

      <BottomGradient />
    </div>
  );
}

const StatusBox = () => {
  return (
    <div className={h.statusWrapper}>
      <div className={h.statusInner}>
        <span className={h.blob} />
        Currently accepting new users
        <Link href="/request-access">Read more</Link>
      </div>
    </div>
  );
};
