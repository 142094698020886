import Avatar from 'boring-avatars';
import { useRouter } from 'next/router';
import type { AvatarProps } from 'types';
import { useUser } from 'utils/useUser';

import styles from './avatar.module.css';

export default function PersonaAvatar({ name, id, linked }: AvatarProps) {
  const { avatars } = useUser();
  const router = useRouter();

  const colours = ['#FFE500', '#83D3FF', '#FC73FF', '#933CFF'];

  let avatarName = 'No Name';

  if (name) {
    avatarName = name.replace(/\s+/g, '-').toLowerCase();
  } else if (!name && id) {
    const getAvatarName = avatars?.find(
      (avatar: any) => avatar?.avatar_id === id
    );
    avatarName = getAvatarName?.avatar_name?.replace(/\s+/g, '-').toLowerCase();
  }

  // console.log('avatarName', avatarName);

  return (
    <div className={styles.avatar}>
      {linked ? (
        <div
          onClick={() => {
            router.push(
              {
                pathname: '/persona',
                query: {
                  key: id,
                },
              },
              '/persona'
            );
          }}
        >
          <Avatar
            size={40}
            square={false}
            name={avatarName}
            variant="marble"
            colors={colours}
          />
        </div>
      ) : (
        <Avatar
          size={40}
          square={false}
          name={avatarName}
          variant="marble"
          colors={colours}
        />
      )}
    </div>
  );
}
