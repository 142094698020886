import Link from 'next/link';
import TimeAgo from 'react-timeago';
import { useUser } from 'utils/useUser';

import styles from './generations.module.css';

interface GenerationsProps {
  t?: string;
}

export default function Generations({ t }: GenerationsProps) {
  const { generations, favourites } = useUser();
  // console.log('gg', generations);
  // console.log('fav', favourites);
  switch (t) {
    case 'favourites':
      // console.log('⭐ Favourites: ', favourites);
      return (
        <div>
          <ul className={styles.generationsList}>
            {favourites.map((f: any) => {
              // console.log('f', f);

              return (
                <li className={styles.generationsItem} key={f.generation_id}>
                  <Link
                    href={`/g/${f.generation_id}`}
                    className={styles.generationItemLink}
                  >
                    <h6 className={styles.generationsItemTitle}>{f.title}</h6>
                  </Link>
                  <span className={styles.generationsItemTime}>
                    <TimeAgo date={f.creation_date} />
                  </span>
                </li>
              );
            })}
          </ul>

          <div className="text-center text-light">
            <Link href="/g/f" className="button button--link">
              View all
            </Link>
          </div>
        </div>
      );

    default:
      // console.log('📦 Generations: ', generations);
      return (
        <div className="mb-3">
          <ul className="my-3 flex flex-col">
            {generations.map((g: any, i: number) => {
              // console.log('g', g);

              // NOTE: Set to 9 to return 10 generations
              if (i > 4) return null;

              return (
                <li key={i} className={styles.generationsItem}>
                  <Link
                    href={`/g/${g.generation_id}`}
                    className={styles.generationItemLink}
                  >
                    <h6 className={styles.generationsItemTitle}>{g.title}</h6>
                  </Link>
                  <span className={styles.generationsItemTime}>
                    <TimeAgo date={g.creation_date} />
                  </span>
                </li>
              );
            })}
          </ul>

          <div className="text-center text-light">
            <Link href="/g" className="button button--link">
              View all
            </Link>
          </div>
        </div>
      );
  }
}
