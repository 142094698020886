export default function EnData() {
  return {
    txt: {
      helloMessage: 'Hello',
      core: {
        title: 'Descripti.ai',
        description: 'Descripti.ai',
      },
      account: {
        title: 'Account',
        login: 'Login',
        loggedIn: 'Logged In',
        logout: 'Sign Out',
        loggedOut: 'Logged Out',
        register: 'Register',
        forgotPassword: 'Forgot Password',
        resetPassword: 'Reset Password',
        changePassword: 'Change Password',
      },
      sideBar: {
        account: 'Account',
        avatars: 'User Avatars',
        settings: 'Settings',
        dashboard: 'Dashboard',
        products: 'Products',
        tools: 'AI Product Tools',
      },
      credits: {
        title: 'Credits',
        subscription: {
          inactive: 'No subscription active, please join a tier',
        },
      },
      generations: {
        title: 'Generations',
        create: 'Create Generation',
        edit: 'Edit Generation',
        delete: 'Delete Generation',
      },
      helpDocs: {
        title: 'Help',
        subtitle:
          'Generate titles, descriptions and metadata for your products here.',
        linkTitle: 'Help Docs',
      },
      faqs: {
        title: "Frequently Asked Question's",
        items: [
          {
            title:
              'What is the AI Generate Product Title, Description, and Meta Data app?',
            content:
              'The AI Generate Product Title, Description, and Meta Data app is a tool designed to help e-commerce businesses automate the process of creating product descriptions, titles, and meta data. This app uses artificial intelligence algorithms to generate high-quality, unique, and optimized content for e-commerce websites. The app is aimed at streamlining the product description creation process and improving the overall SEO performance of e-commerce websites.',
          },
          {
            title: 'Can the app generate content for a custom avatar?',
            content:
              'Yes, the AI Generate Product Title, Description, and Meta Data app allows users to generate content for a custom avatar. This means that the app can be customized to reflect the tone, style, and voice of the brand, making it easier for e-commerce businesses to create a consistent and personalized customer experience.',
          },
          {
            title: 'Is the generated content unique and original?',
            content:
              'The app is designed to generate unique and original content for each product. The artificial intelligence algorithms used by the app can create high-quality descriptions that are not only unique but also optimized for search engines. This helps to improve the visibility of e-commerce websites on search engines and attract more customers.',
          },
          {
            title: 'Does the app support multiple languages?',
            content:
              'The availability of multiple language support depends on the version of the AI Generate Product Title, Description, and Meta Data app you are using. If you need support for multiple languages, please contact the support team for more information.',
          },
          {
            title: 'Is the app easy to use?',
            content:
              'The AI Generate Product Title, Description, and Meta Data app has been designed with user-friendliness in mind. The app has a simple and intuitive interface that does not require any technical skills to use. This makes it easy for e-commerce businesses of all sizes to generate high-quality product descriptions and titles without any hassle.',
          },
          {
            title: 'How does the app improve e-commerce SEO?',
            content:
              'The AI Generate Product Title, Description, and Meta Data app generates optimized product titles, descriptions, and meta data, which can improve the visibility of e-commerce websites on search engines. By using keywords and phrases relevant to the product, the app can improve the search engine ranking of e-commerce websites, making it easier for customers to find the products they are looking for.',
          },
          {
            title: 'Can the app be integrated with my e-commerce platform?',
            content:
              'The AI Generate Product Title, Description, and Meta Data app can be integrated with a variety of e-commerce platforms, including Magento, Shopify, and WooCommerce. If you are unsure about the compatibility of the app with your e-commerce platform, please contact the support team for more information.',
          },
          {
            title: 'Is there a free trial available?',
            content:
              'Yes, a free trial is available for the AI Generate Product Title, Description, and Meta Data app. This allows e-commerce businesses to test the app and evaluate its features and capabilities before making a purchase. To find out more about the free trial, please contact us.',
          },
          {
            title: 'Is support available for the app?',
            content:
              'Yes, support is available for the AI Generate Product Title, Description, and Meta Data app. If you need assistance or have any questions, please do not hesitate to contact the support team. They will be happy to help you with any issues or concerns you may have.',
          },
        ],
      },
    },
  };
}
