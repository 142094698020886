export default function LinkedIn() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
      <g clipPath="url(#a)">
        <path
          fill="#0A66C2"
          d="M44.4567 0H3.54333c-.93975 0-1.84101.373315-2.50551 1.03782C.373315 1.70232 0 2.60358 0 3.54333V44.4567c0 .9397.373315 1.841 1.03782 2.5055C1.70232 47.6267 2.60358 48 3.54333 48H44.4567c.9397 0 1.841-.3733 2.5055-1.0378C47.6267 46.2977 48 45.3964 48 44.4567V3.54333c0-.93975-.3733-1.84101-1.0378-2.50551C46.2977.373315 45.3964 0 44.4567 0Zm-30.15 40.89H7.09V17.9667h7.2167V40.89Zm-3.6134-26.1c-.81857-.0046-1.61747-.2516-2.29583-.7098-.67836-.4582-1.20579-1.1071-1.51572-1.8648-.30992-.7577-.38845-1.5902-.22569-2.39249.16277-.80228.55954-1.53836 1.14025-2.11535.58071-.57699 1.31932-.96903 2.12262-1.12664.80327-.15761 1.63527-.07373 2.39097.24105.7557.31478 1.4012.84636 1.855 1.52765.4539.6813.6957 1.48176.6951 2.30038.0077.5481-.095 1.0921-.302 1.5996-.207.5075-.514.9682-.9029 1.3545-.3888.3864-.8514.6905-1.3603.8942-.5088.2037-1.0535.3029-1.6015.2917Zm30.2134 26.12h-7.2134V28.3867c0-3.6934-1.57-4.8334-3.5966-4.8334-2.14 0-4.24 1.6134-4.24 4.9267v12.43H18.64V17.9833h6.94V21.16h.0933c.6967-1.41 3.1367-3.82 6.86-3.82 4.0267 0 8.3767 2.39 8.3767 9.39l-.0033 14.18Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
