import { ai } from 'utils/data';

import styles from './info.module.css';

export default function Info() {
  return (
    <div className={styles.wrapper}>
      <p>
        <strong>Model:</strong> {ai.model}
      </p>
    </div>
  );
}
