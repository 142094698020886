import Icon from 'components/ui/icons';
import Link from 'next/link';
import { useRouter } from 'next/router';

// import { useState } from 'react';
import styles from './cardMenu.module.css';

interface CardMenuProps {
  cardTitle: string;
  cardSubtitle: string;
  cardLink: string;
}

export default function CardMenu(props: CardMenuProps) {
  let activeItem = false;
  const router = useRouter();

  if (props.cardLink === router.pathname) {
    activeItem = true;
  }

  return (
    <Link
      href={props.cardLink}
      className={`${styles.cardMenu} cardMenu ${
        activeItem ? styles.active : styles.inactive
      }`}
    >
      <div className={styles.cardMenuHeadings}>
        <h6>{props.cardTitle}</h6>
        {/* <p>{props.cardSubtitle}</p> */}
      </div>
      <Icon name="chevron" />
    </Link>
  );
}
