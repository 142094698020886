import Image from 'next/image';
import type { AuthorProps } from 'types';

import styles from './author.module.css';

export default function Author({ content }: AuthorProps) {
  const { name, picture } = content || {};

  return (
    <div className={styles.wrapper}>
      <Image src={picture} width={24} height={24} alt={name} />
      <p>{name}</p>
    </div>
  );
}
